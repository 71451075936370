import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Button,
    Alert
} from '@mui/material';

import SelectTable from "../../../Components/Chavelusa/SelectTable";

import {
    buildParams,
    requestGET,
    handleRequestError
} from '../../../utils';

import Window from "../../../Components/Chavelusa/Window";

import { useNavigate } from "react-router";

let aborter = null;

/**
 * Optional parameters when page is used as an inner component
 * 
 * @param {Method} (Optional) goBack When defined, a go back button is displayed, which calls this method when activated
 * @param {String} (Optional) urlAlt alternative URL to add to append to process.env.REACT_APP_API_URL
 * @param {String} (Optional) utilsAlt By default utils used is utils_popetyList.js. Provide alternative filename to use it. Path must be relative to current folder.
 * @param {Object} (Optional) initialParams Initial value for parameters
 * @param {Method} (Optional) onSubmit Method called when user submits rows selection (array with property ids)
 */
const PropertyList = ({
    goBack, urlAlt, utilsAlt, initialParams, onSubmit, defaultOrderBy
}) => {

    // Loading
    const [loading, setLoading] = useState(true);
    // Content loading and management
    const utils = utilsAlt ? require('./' + utilsAlt) : require('./utils_propertyList.js');
    const url = process.env.REACT_APP_API_URL + (urlAlt ? urlAlt : '/realstate/properties/');
    const urlMessagesPending = process.env.REACT_APP_API_URL + '/realstate/properties/hasMessagesPending';
    const [params, setParams] = useState(initialParams ? initialParams : {});
    const [data, setData] = useState(null);
    const [messagesPending, setMessagesPending] = useState(0);

    // When component initialized, load filters
    useEffect(() => {
        loadFilters();

        if (!urlAlt) {
            requestGET(urlMessagesPending)
                .then(response => {
                    setMessagesPending(response['hasPendingMessages']);
                }).catch(error =>
                    handleRequestError(
                        error,
                        [],
                        "Error getting messages pending",
                    )
                );
        }
    }, []);

    useEffect(() => {
        // Load clients from API
        const timeOutId = setTimeout(() => loadData(), 500);
        return () => clearTimeout(timeOutId);
    }, [params]);

    // Get data from API
    const loadData = () => {
        setLoading(true);

        // cancel pending request if any
        if (aborter) aborter.abort();
        // make our request cancellable
        aborter = new AbortController();
        const signal = aborter.signal;

        requestGET(url + buildParams(params), signal)
            .then(response => {
                aborter = null;
                setData(response);
                setLoading(false);
            }).catch(error =>
                handleRequestError(
                    error,
                    [],
                    "Error getting clients list"
                )
            ).finally(() => {
                aborter = null;
            });
    }

    // Get filters from API
    const [filters, setFilters] = useState([]);
    const [filtersData, setFiltersData] = useState({});
    const loadFilters = () => {
        requestGET(process.env.REACT_APP_API_PUBLIC_URL + '/realstate/counties/')
            .then(response => {
                setFiltersData(f => { return { ...f, counties: response } });
            }).catch(error =>
                handleRequestError(error, [], "Error getting filter counties")
            );
        requestGET(process.env.REACT_APP_API_PUBLIC_URL + '/realstate/features/')
            .then(response => {
                setFiltersData(f => { return { ...f, features: response } });
            }).catch(error =>
                handleRequestError(error, [], "Error getting filter features")
            );
        requestGET(process.env.REACT_APP_API_PUBLIC_URL + '/realstate/types/')
            .then(response => {
                setFiltersData(f => { return { ...f, types: response } });
            }).catch(error =>
                handleRequestError(error, [], "Error getting filter types")
            );
        requestGET(process.env.REACT_APP_API_URL + '/realstate/states')
            .then(response => {
                setFiltersData(f => { return { ...f, states: response } });
            }).catch(error =>
                handleRequestError(error, [], "Error getting filter states")
            );
        requestGET(process.env.REACT_APP_API_URL + '/realstate/agents')
            .then(response => {
                setFiltersData(f => { return { ...f, agents: response } });
            }).catch(error =>
                handleRequestError(error, [], "Error getting filter agents")
            );
        requestGET(process.env.REACT_APP_API_PUBLIC_URL + '/realstate/filters/')
            .then(response => {
                setFiltersData(f => { return { ...f, filters: response } });
            }).catch(error =>
                handleRequestError(error, [], "Error getting filter filters")
            );
        requestGET(process.env.REACT_APP_API_URL + '/realstate/status')
            .then(response => {
                setFiltersData(f => { return { ...f, status: response } });
            }).catch(error =>
                handleRequestError(error, [], "Error getting filter status")
            );
    }
    useEffect(() => {
        setFilters(utils.buildFilters(filtersData));
    }, [filtersData]);

    const addParam = (newVals) => {
        setParams({
            ...params,
            ...newVals
        })
    }

    /**
     * A method to update current state 
     * @param {String[]} paramsRemove An array with the ids of the fields to delete
     * @param {String} id The id of the field to update 
     * @param {*} val The value to update the last with     
     */
    const updateParams = (paramsRemove, id = null, val = null) => {
        let copy = null;
        if (id) {
            copy = { ...params, [id]: val }
        } else {
            copy = { ...params }
        }
        paramsRemove.forEach((key) => {
            delete copy[key];
        })
        setParams(copy);
    }

    // Window 
    const [window, setWindow] = useState(undefined);
    const [windowChanged, setWindowChanged] = useState(false);
    useEffect(() => {
        // When window changes, reset changed property
        if (window === null)
            setWindowChanged(false);
    }, [window]);

    // Add entity 
    const navigate = useNavigate();
    const addEntity = (entity) => {
        if (!entity) return;
        navigate('/propriedades/' + entity.id + '?edit=true');
    }

    return (
        <Grid className="property_list">
            {
                messagesPending!==undefined && messagesPending>0 &&
                <Alert
                    severity="error"
                    sx={{
                        mb: 3
                    }}
                    action={
                        <Button 
                            color="inherit" 
                            onClick={() => navigate("?filter=pending_messages:true")}
                            sx={{ml: 'auto'}}
                        >
                            Aplicar filtro
                        </Button>
                    }
                >
                    Existem {messagesPending} propriedades com mensagens pendentes
                </Alert>
            }
            <SelectTable
                goBack={goBack}
                entityNameSingular={'propriedade'}
                data={data}
                loading={loading}
                getCols={utils.getCols}
                getRow={utils.getRow}
                defaultOrder='desc'
                defaultOrderBy={defaultOrderBy ? defaultOrderBy : 'created'}
                filters={filters}
                params={params}
                addParam={addParam}
                updateParams={updateParams}
                tutorial={utils.buildTutorial}
                onSubmit={onSubmit}
                allowSelect={!!utils.enableSelect}
                allowSearch={!!utils.enableSearch}
                addNew={
                    !utils.addNew
                        ? null
                        : () => setWindow(utils.addNew(
                            () => setWindow(null),
                            addEntity,
                            () => setWindowChanged(true)
                        ))
                }
                lowDensitySupport={utilsAlt ? false : true}
            />

            {
                window &&
                <Window close={() => setWindow(null)} hasChanged={windowChanged}>{window}</Window>
            }
        </Grid>
    );
}

export default PropertyList;