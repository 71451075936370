import React, { useState, useEffect } from "react";

import {
    useParams
} from "react-router-dom";

import {
    is_agent
} from '../../../../Services/permissions';

import {
    openChaveLusaAdmin,
    requestGET,
    handleRequestError
} from '../../../utils';

import {
    Box,
    Toolbar,
    Typography,
    IconButton,
    Tooltip,
    CircularProgress,
    TextField,
    Menu,
    MenuItem,
    Grid,
    Button,
    Snackbar,
    Alert
} from '@material-ui/core';

import {
    CorporateFare,
    Help as HelpIcon
} from '@mui/icons-material';

import { Steps } from 'intro.js-react';

import { tutorial } from './utils';

import Window from "../../../Components/Chavelusa/Window";
import Contacts from "../../../Components/Chavelusa/Contacts";
import ClientForm from "../../../Components/CRM/Client/Form";
import FormNotes from "../../../Components/Chavelusa/FormNotes";
import ClientArchive from "../../../Components/CRM/Client/Archive";

import Tags from "./Tags";
import Feed from "./Feed";


const ClientPage = () => {

    const url = process.env.REACT_APP_API_URL + '/crm/client_agents/';

    // URL Data
    let { id } = useParams();

    // Get user data from API
    const [loading, setLoading] = useState(true);
    const [clientAgent, setClientAgent] = useState(null);
    const [notify, setNotify] = useState(undefined);
    useEffect(() => {
        loadClientAgent();
    }, [id]);

    const loadClientAgent = () => {
        // Load client agent instance from API
        requestGET(url + id + '/')
            .then(response => {
                console.log("GOT ClientAgent", response);
                if (response.replaced_by) {
                    window.location.replace("/clientes/" + response.replaced_by);
                } else {
                    setClientAgent(response);
                }
            }).catch(error =>
                handleRequestError(
                    error,
                    [],
                    "Error getting client agent",
                ).then(() => {
                    window.location.replace("/clientes/");
                })
            ).finally(() => {
                setLoading(false);
            });
    }

    // Lateral window
    const [rWindow, setWindow] = useState(null);
    const [windowChanged, setWindowChanged] = useState(false);
    const [windowPipe, setWindowPipe] = useState([]);
    useEffect(() => {
        // When window changes, reset changed property
        if (rWindow === null)
            setWindowChanged(false);
        else
            setWindowPipe(wp => {
                wp.push(rWindow);
                return wp;
            })
    }, [rWindow]);
    const windowPipePop = () => {
        if (windowPipe.length === 0)
            return null;
        if (windowPipe.length === 1)
            return windowPipe[windowPipe.length - 1];
        return windowPipe[windowPipe.length - 2];
    }

    // TUTORIAL
    const [showTutorial, setShowTutorial] = React.useState(false);

    const goingToStep = (nextIndex) => {
        // When opening a tutotial tooltip, scroll into element
        // (Specially for mobile which has table horizontal scroll)
        if (tutorial && nextIndex < tutorial.length && document.querySelector(tutorial[nextIndex]['element'])) {
            document.querySelector(tutorial[nextIndex]['element']).scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"

            pr={rWindow ? 3 : 0}
            sx={{
                margin: 'auto',
                minHeight: '50vh'
            }}
            className='client_agents_list scalable'
        >
            {
                loading &&
                <CircularProgress sx={{ mt: 'auto', mx: 'auto' }} />
            }
            {
                !loading && clientAgent &&
                <Grid container direction="row" xs={12}>
                    { /* MAIN CONTAINER */}
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        xs={!rWindow ? 8 : 6}
                        wrap="nowrap"
                    >
                        <div className="client-header">
                            { /* HEADER */}
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                            >
                                <Typography variant='h4' sx={{ fontWeight: 500 }}>
                                    {clientAgent.client.realstate &&
                                        <Tooltip title="Agência imobiliária">
                                            <CorporateFare color="primary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                                        </Tooltip>
                                    }
                                    {clientAgent.client.name}
                                </Typography>
                                <Grid container direction="row" ml="auto" width="auto" className="operations">
                                    {
                                        !clientAgent.archived &&
                                        <Grid>
                                            <Button onClick={() => {
                                                setWindow(null);
                                                setTimeout(() =>
                                                    setWindow(
                                                        <ClientForm
                                                            client={clientAgent.client}
                                                            clientAgentId={clientAgent.id}
                                                            agent={clientAgent.agent}
                                                            success={(entity) => { setWindow(null); setNotify({ text: "O cliente foi atualizado com sucesso!" }); setClientAgent(entity); }}
                                                            formChange={() => setWindowChanged(true)}
                                                        />
                                                    )
                                                    , 500);
                                            }} color="primary" variant="outlined" sx={{ mr: 1 }}>
                                                Editar
                                            </Button>
                                        </Grid>
                                    }
                                    <ClientArchive
                                        clientAgent={clientAgent}
                                        success={(entity) => {
                                            setNotify({ text: "O cliente foi atualizado com sucesso!" });
                                            setClientAgent(entity);
                                            setTimeout(() => window.location.reload(), 1500)
                                        }}
                                        errorCallback={(message) => setNotify({ text: message, error: true })}
                                    />
                                </Grid>
                            </Grid>

                            { /* TAGS */}
                            <Tags
                                clientAgent={clientAgent}
                                show_agent={!is_agent}
                            />

                            { /* CONTACTS */}
                            <Contacts
                                phone={clientAgent.client.phone}
                                phoneSecondary={clientAgent.client.phone2}
                                email={clientAgent.client.email}
                                emailSecondary={clientAgent.client.email2}
                                preferential={clientAgent.client.preferential}
                            />

                            { /* NOTES */}
                            <FormNotes
                                noteOriginal={clientAgent.client.notes}
                                url={process.env.REACT_APP_API_URL + '/crm/client_agents/' + clientAgent.id + '/'}
                                attribute={'client.notes'}
                                onUpdate={(instance) => {
                                    setNotify({ text: 'As notas foram guardadas com sucesso' });
                                    setClientAgent(instance);
                                }}
                                deactivated={clientAgent.archived}
                            />

                        </div>

                        { /* FEED */}
                        <Feed
                            clientAgent={clientAgent}
                            setWindow={setWindow}
                            window={rWindow}
                            windowChanged={() => setWindowChanged(true)}
                            windowChangedState={windowChanged}
                            windowPipePop={windowPipePop}
                            setNotify={(text) => setNotify({ text: text })}
                            onUpdate={loadClientAgent}
                        />
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-end"
                        xs={!rWindow ? 4 : 6}
                    >
                        <Tooltip title="Ajuda">
                            <IconButton
                                onClick={() => setShowTutorial(true)}
                            >
                                <HelpIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            }

            {
                rWindow &&
                <Window close={() => setWindow(null)} hasChanged={windowChanged}>{rWindow}</Window>
            }

            {
                <Snackbar
                    open={notify != undefined && notify != false}
                    autoHideDuration={6000}
                    onClose={() => setNotify(undefined)}
                >
                    {
                        notify &&
                        <Alert onClose={() => setNotify(undefined)} severity={notify.error ? "error" : "success"} sx={{ width: '100%' }}>
                            {notify.text}
                        </Alert>
                    }
                </Snackbar>
            }

            <Steps
                enabled={showTutorial}
                steps={tutorial}
                initialStep={0}
                onExit={() => setShowTutorial(false)}
                onBeforeChange={goingToStep}
                options={{
                    nextLabel: 'Próximo',
                    prevLabel: 'Anterior',
                    doneLabel: 'Concluir',
                    hidePrev: true,
                    disableInteraction: true
                }}
            />
        </Grid>
    );
}

export default ClientPage;