import React, {useState, useEffect} from "react";

import { 
    Grid,
    Typography,
    Tooltip,
    Chip
} from '@mui/material';

import {
    Event as EventIcon,
    Person as PersonIcon,
    AttachFile as AttachFileIcon,
    Newspaper as NewspaperIcon,
    SyncDisabled as SyncDisabledIcon,
    ErrorOutline as ErrorOutlineIcon
} from '@mui/icons-material';

import {
    DateHelper
} from '../../../../utils';

import {
    pp_status_approved,
    pp_status_error,
    pp_status_neutral,
    pp_status_pending
} from '../utils';


import { canReadAdministrativeData } from "../../../../../Services/permissions";

import PropertyPublishedWindow from "../Window";

const PropertyPublishedBanner = ({app, property, selected, setWindow, updateElement, setNotify}) => {

    const getWindow = () => {
        return (<PropertyPublishedWindow 
            app={app}
            property={property}
            updateElement={updateElement}
            setNotify={setNotify}
        />);
    }

    return (
        <Grid
            container
            direction="row"
            className={!selected ? "elementSelectable" : "elementSelectable selected"}
            my={4}
            onClick={() => setWindow(getWindow())}
        >
            <Grid
                xs={2}
                m={0}
                container
            >
                <Typography variant="h3" sx={{ my: 'auto', mx: 'auto' }}>
                    <NewspaperIcon 
                        color={
                            app.pp && pp_status_approved.includes(app.pp.status) ? 'primary'
                            : app.pp && pp_status_error.includes(app.pp.status) ? 'error'
                            : app.pp && pp_status_pending.includes(app.pp.status) ? 'warning'
                            : 'secondary'
                        } 
                        fontSize="inherit"
                    /> 
                </Typography>
            </Grid>
            <Grid
                justifyContent="center"
                container
                direction="column"
                p={3}
                xs={10}
            >
                <Typography variant="h6" mb={0}>{app.name}</Typography>
                <Grid container>
                    <Typography variant="caption" mr={2}>
                        <Tooltip title="Estado">
                            <AttachFileIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                        </Tooltip>
                        {app.pp ? app.pp.status : 'Propriedade não anunciada no parceiro'}
                    </Typography>
                    {
                        app.pp &&  
                        <>
                            <Typography variant="caption" mr={2}>
                                <Tooltip title="Publicada a">
                                    <EventIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                                </Tooltip>
                                {new Date(app.pp.created).toLocaleDateString('pt-PT', DateHelper.format_date_time)}
                            </Typography>
                            <Typography variant="caption" mr={2}>
                                <Tooltip title="Publicado por">
                                    <PersonIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                                </Tooltip>
                                {app.pp.user.name}
                            </Typography>
                        </>
                    }
                </Grid>
                {
                    setWindow && !app.has_access && canReadAdministrativeData ?
                    <Chip
                        label="Sem accesso"
                        icon={<ErrorOutlineIcon fontSize="small" />}
                        color="error"
                        sx={{ mt: 3 }}
                    />
                    : setWindow && app.pp && pp_status_error.includes(app.pp.status) 
                    ? <Chip
                        label="Anúncio reprovado"
                        icon={<ErrorOutlineIcon fontSize="small" />}
                        color="error"
                        sx={{ mt: 3 }}
                    />
                    : setWindow && app.pp && (new Date(app.pp.last_sync) < new Date (property.last_changed)) &&
                    <Chip
                        label="Propriedade atualizada após publicação do anúncio"
                        icon={<SyncDisabledIcon fontSize="small" />}
                        color="warning"
                        sx={{ mt: 3 }}
                    />
                }
            </Grid>
        </Grid>
    );
}

export default PropertyPublishedBanner;