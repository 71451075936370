import React, {useState, useEffect} from "react";

import { 
    Grid,
    Typography,
    Button,
    CircularProgress,
    Alert
} from '@mui/material';

import FormGroup from '../../../Chavelusa/FormGroup';
import Stepper from '../../../Chavelusa/Stepper';

import { default as ChaveLusaAlert } from "../../../Chavelusa/Alert";

import {
    requestWithBody, 
    requestGET,
    handleRequestError
} from "../../../../utils";

import {
    is_agent,
    canWriteSensitiveData
} from "../../../../../Services/permissions";

import {
    buildFilterText,
    buildFilterRadio,
    validatorMin3Chars,
    validatorEmail,
    validatorPhone,
    buildFilterSelect,
    buildFilterCheckboxOnlyOne
} from '../../../Chavelusa/FormGroup/utils';

const fields = [
    buildFilterText('Nome', 'name', true, validatorMin3Chars, 'O nome deve ter no mínimo 3 caracteres.'),
    buildFilterText('Email', 'email', true, validatorEmail),
    buildFilterText('Email secundário', 'email2', false, validatorEmail),
    buildFilterText('Contacto principal', 'phone', true, validatorPhone),
    buildFilterText('Contacto secundário', 'phone2', false, validatorPhone),
    buildFilterCheckboxOnlyOne(
        'Agência imobiliária',
        'realstate'
    ),
    buildFilterRadio('Método de contacto preferencial', 'preferential', [
        {label: 'Chamada', id: 'C'},
        {label: 'Email', id: 'E'},
        {label: 'Não definido', id: ''}
    ], false),
]

if (!is_agent) {
    fields.unshift(buildFilterSelect('Agente', 'select', 'agent_id', [], null, true, null));
}

const ClientForm = ({client, clientAgentId, agent, success, formChange}) => {

    const edit = client && clientAgentId;
    const url = 
        edit 
        ? process.env.REACT_APP_API_URL + '/crm/client_agents/' + clientAgentId + '/'
        : process.env.REACT_APP_API_URL + '/crm/client_agents/';

    console.log("COMPONENT CLientForm", client);

    // Agent change
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (!is_agent) {
            requestGET(process.env.REACT_APP_API_URL + '/realstate/agents/')
            .then(response => {
                console.log("GOT agents", response);
                fields[0]['values'] = response.map(agent => {return {label: agent.name, id: agent.id}});
                setLoading(false);
            });
        } else {
            setLoading(false);
        }

        // If user does not have permissions, block changes on fields with values set
        if (!canWriteSensitiveData) {
            if (!is_agent && clientAgentId) fields[0].disabled = true;
            if (client && client.email) fields[is_agent ? 1 : 2].disabled = true;
            if (client && client.email2) fields[is_agent ? 2 : 3].disabled = true;
            if (client && client.phone) fields[is_agent ? 3 : 4].disabled = true;
            if (client && client.phone2) fields[is_agent ? 5 : 4].disabled = true;
        }
    }, [client]);

    // Handling form submition
    const [error , setError] = useState(undefined);
    const [submitting, setSubmitting] = useState(false);
    const [clientSubmitted, setClientSubmitted] = useState(undefined);
    const [backToMenu, setBackToMenu] = useState(false);
    const [clientDuplicate, setClientDuplicate] = useState(undefined);

    const [alert, setAlert] = useState(undefined);
    const ALERT_CONFIRM_CHANGE_AGENT = {
        title: 'Tem a certeza que quer migrar o cliente para outro agente?',
        text: 'Ao continuar, o agente original irá perder acesso à ficha deste cliente sem aviso prévio.',
        action: undefined
    };

    const submitClient = (formData) => {
        console.log("Submitting form client with data:", formData);

        setSubmitting(true);

        var body = {};
        if ('agent_id' in formData) {
            body['agent_id'] = formData.agent_id;
            delete formData.agent_id;
        }
        body['client'] = formData;

        // EDIT
        requestWithBody(
            edit ? "PATCH" : "POST",
            url,
            body
        ).then(response => {
            console.log(
                edit ? "Client updated with success!" : "Client created with success!", 
                response
            );
            // If client has been replaced, redirect to new client page
            if (response.replaced_by) {
                window.location.replace("/clientes/" + response.replaced_by);
            } else {
                success(response);
                setClientSubmitted(response);
                setBackToMenu(old => !old);
            }
        }).catch(error => 
            handleRequestError(
                error, 
                [], 
                edit ? "Error updating client!" : "Error creating client!", 
                (e) => {
                    if ('client' in e) {
                        return e['client'];
                    }
                    return null;
                }
            )
            .then(e => {
                if ('clientDuplicate' in e && e['clientDuplicate']) {
                    setClientDuplicate(e['clientDuplicate']);
                } else {
                    setError(e);
                }
            })
        ).finally(() => {
            setSubmitting(false);
        });

    }

    const form = (<FormGroup 
        fields={fields}
        initialVals={agent ? (client ? {...client, agent_id:agent.id} : {agent_id: agent.id}) : (client ? client : {})}
        onSubmit={(formData) => {
            if (agent && 'agent_id' in formData && formData['agent_id']!=agent.id) {
                ALERT_CONFIRM_CHANGE_AGENT.action = () => submitClient(formData);
                setAlert(ALERT_CONFIRM_CHANGE_AGENT);
            } else {
                submitClient(formData);
            }
        }}
        disabled={submitting}
        errors={error}
        setErrors={setError}
        sx={{
            minWidth: '100%',
            maxWidth: '100%',
            mr: 0,
            my: 1
        }}
        updateParams={() => formChange()}
    />);

    // Client form steps
    let steps = [
        {
            label: 'Dados do cliente',
            component: form
        }
    ];
    if (is_agent || canWriteSensitiveData) {
        steps.push({
            label: 'Qualificação',
            url: clientSubmitted ? ('/clientes/' + clientSubmitted.id + '/?op=classify') : '#'
        });
    }

    // When adding new, Stepper, otherwise, just form
    return (
        <Grid sx={{width:'100%'}}>
            {
                clientDuplicate 
                ?
                <>
                    <Typography variant="h6" mb={3}>
                        Cliente duplicado!
                    </Typography>
                    <Typography mb={1}>
                        Os dados inseridos colidem com os de outro cliente já existe na base de dados.
                    </Typography>
                    {
                        canWriteSensitiveData
                        ?
                        <Typography>
                            Os administradores foram notificados. Quando a situação for regularizada será alertado por email.
                        </Typography>
                        : 
                        <>
                            <Typography mb={3}>
                                Deve aceder à página do cliente para proceder ao desbloqueio deste conflito.
                            </Typography>
                            <Button color="primary" variant="contained" href={"/clientes/duplicados/" + clientDuplicate.id}>
                                Desbloquear cliente    
                            </Button>
                        </>
                    }
                </>
                :
                client 
                ?
                <>
                    <Typography variant="h6" mb={0}>
                        Editar cliente
                    </Typography>
                    <Typography variant="caption" mb={0}>
                        Criado a {new Date(client.created).toLocaleDateString('pt-PT', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })},
                        última alteração a {new Date(client.last_changed).toLocaleDateString('pt-PT', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                    </Typography>
                    {
                        loading 
                        ? <CircularProgress sx={{ mt: 'auto', mx: 'auto', width: '100%' }} />
                        : form
                    }
                </>
                :
                <Stepper 
                    title={'Adicionar cliente'}
                    steps={steps}
                    completed={clientSubmitted ? [0] : []}
                    activeStepsInitial={clientSubmitted ? [1] : [0]}
                    goBack={clientSubmitted ? success : undefined}
                    backToMenu={backToMenu}
                />
            }


            { /* Operations confirmation box */}
            {
                alert &&
                <ChaveLusaAlert
                    title={alert.title}
                    text={alert.text}
                    action={alert.action}
                    close={() => setAlert(undefined)}
                />
            }
        </Grid>
    );
}

export default ClientForm;