import moment from "moment";


const TimeService = {
    getGreeting: () => {
        var currentHour = moment().format("HH");

        if (currentHour >= 3 && currentHour < 12) {
            return "Bom dia";
        } else if (currentHour >= 12 && currentHour < 20) {
            return "Boa tarde";
        } else if (currentHour >= 20 || currentHour < 3) {
            return "Boa noite";
        } else {
            return "Olá"
        }
    }
}

export default TimeService;