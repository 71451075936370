import React, { useState, useEffect } from "react";

import {
    useParams,
    useLocation
} from "react-router-dom";

import {
    Grid,
    Typography,
    Skeleton,
    CircularProgress,
    Fade,
    Tooltip
} from '@mui/material';

import {
    Home as HomeIcon,
    LocationOn as LocationOnIcon,
    AttachFile as AttachFileIcon,
    SquareFoot as SquareFootIcon,
    Roofing as RoofingIcon,
    KingBed as KingBedIcon,
    Shower as ShowerIcon,
    Garage as GarageIcon
} from '@mui/icons-material';

import QRCode from "react-qr-code";

import {
    requestGET,
    handleRequestError,
    colors
} from '../../../../utils';

const PropertyTVPresentation = () => {

    // URL Data
    let { id } = useParams();

    // 1. PRESENTATION LOADING
    const [code, setCode] = useState(undefined); // PresentationAccessCode
    const [presentation, setPresentation] = useState(undefined); // Presentation
    const [properties, setProperties] = useState(undefined); // Property[]
    const [primaryColor, setPrimaryColor] = useState(colors.primary); // Str (hex color code)
    const [error, setError] = useState(false); // Bool (flag - set when API call fails, shows error message on UI)

    // 1.1. When view rendered, call API for presentation (or access code if user not authenticated)
    useEffect(() => {
        if (code) return;
        loadPresentation();
    }, [])

    const loadPresentation = () => {
        let url = process.env.REACT_APP_API_URL + '/realstate/presentations/' + id + '/';
        console.log("loadPresentation", code);
        if (code && code.code)
            url += '?code=' + code.code
        requestGET(url)
            .then(response => {
                if (response.code) {
                    console.log("GOT code", response);
                    setCode(response);
                } else {
                    console.log("GOT presentation", response);
                    setPresentation(response);
                }
            }).catch(error =>
                handleRequestError(
                    error,
                    [],
                    "Error getting presentation",
                ).then(() => setError(true))
            );
    }

    // 1.2. Every time a code is retieved, schedule its reloading, to update state (will stop when presentation is retrieved instead of code) 
    useEffect(() => {
        if (!code) return;
        setTimeout(() => loadPresentation(), 5000);
    }, [code]);

    // 2. PROPERTIES LOADING
    // 2.1. Once the presentation is set, load the properties that match its filters
    // (also set primary color)
    useEffect(() => {
        if (!presentation) return;

        setPrimaryColor(presentation.primary_color);

        var url = process.env.REACT_APP_API_PUBLIC_URL + '/realstate/properties/allImages?';
        if (presentation.filterCounties && presentation.filterCounties.length > 0)
            url += '&parish__county__in=' + presentation.filterCounties.map(fc => fc.county.id);
        if (presentation.filterStates && presentation.filterStates.length > 0)
            url += '&state__in=' + presentation.filterStates.map(fs => fs.state);
        if (presentation.filterProperties && presentation.filterProperties.length > 0)
            url += '&id__in=' + presentation.filterProperties.map(fp => fp.property.id);
        requestGET(url)
            .then(response => {
                console.log("GOT properties", response);
                setProperties(response.filter(p => !!p.images && p.images.length > 0));
            }).catch(error =>
                handleRequestError(
                    error,
                    [],
                    "Error getting properties",
                ).then(() => setError(true))
            );
    }, [presentation])


    // 3. PRESENTATION MANAGEMENT
    const [transitioning, setTransitioning] = useState(undefined); // Bool (Starts as undefined)
    // (when true, fades in primary color overlay that hides content, so that it can be changed 
    //  dinamically; once fade finishes, automatically changes state to false) 
    const [slide, setSlide] = useState(undefined); // Object
    // {type: PROPERTY, index: <integer>}
    // {type: DIVIDER, nextPropertyIndex: <integer>}
    const [elapsed, setElapsed] = useState(0); // Int (% of time elapsed for current slide)

    const TYPE_PROPERTY = 'PROPERTY';
    const TYPE_DIVIDER = 'DIVIDER';

    // 3.1. Once the properties are retrieved from the API, start presentation by triggering transition :)
    useEffect(() => {
        if (!properties) return;
        console.log("READY", properties);
        // Start transition
        setTransitioning(true);
    }, [properties]);

    // 3.2. When transition finishes (false value), update slide 
    useEffect(() => {
        if (transitioning === true || transitioning === undefined) return;

        const newSlide = getNextSlide(slide);
        console.log("newSlide", newSlide);
        setSlide(newSlide);

        // Start loading images for property after newSlide so that it is already cached when slide appears
        var nextIndex = newSlide.index ? newSlide.index + 1 : newSlide.nextPropertyIndex;
        if (!nextIndex || nextIndex + 1 > properties.length) nextIndex = 0;
        properties[nextIndex].images.forEach((image, index) => {
            if (index > presentation.maxPhotosPerProperty) return;
            const img = new Image();
            img.src = process.env.REACT_APP_STATIC + image.image;
        });
    }, [transitioning]);

    // 3.3. When slide changes, update slide related values
    useEffect(() => {
        if (!slide) return;
        updateSlideRelatedValues(null);
    }, [slide]);

    // HELPERS
    let timeoutId = undefined;
    const updateSlideRelatedValues = (lastUpdate) => {
        // If scheduled execution, cancel it
        if (timeoutId) {
            clearTimeout(timeoutId);
            timeoutId = undefined;
        }

        // Compute values
        const timeBetweenSlides = presentation.secondsPerPhoto * 1000; // milisseconds
        const slidePhotos = slide && slide.type === TYPE_PROPERTY ? Math.min(presentation.maxPhotosPerProperty, properties[slide.index].images.length) : 1;
        const totalTime = timeBetweenSlides * slidePhotos;
        console.log("slide", slide, "slidePhotos", slidePhotos, "totalTime", totalTime);

        // Compute elapsed time and update it
        let now = new Date();
        let elapsedMs = !!lastUpdate ? now.getTime() - lastUpdate.getTime() : 0;
        setElapsed((elapsedMs / totalTime) * 100);

        // If elapsed time has been reached, start transition to next slide
        if (elapsedMs >= totalTime) {
            setTransitioning(true);
        } else { // Otherwise, schedule next state update
            timeoutId = setTimeout(() => updateSlideRelatedValues(lastUpdate ? lastUpdate : now), 100);
        }
    }

    const getNextSlide = (prevSlide) => {
        /**
         * SLIDE
         
         * 
         * Show a DIVIDER for every 3 PROPERTIES SHOWN
         */
        console.log("getNextSlide", properties, prevSlide);
        if (
            !prevSlide // Show DIVIVER at start...
            || !properties || !properties.length
            || prevSlide.type === TYPE_PROPERTY && ((prevSlide.index + 1) % 3 === 0 || prevSlide.index + 1 >= properties.length) // ... for every 3 properties shown and after last property
        )
            return {
                type: TYPE_DIVIDER,
                nextPropertyIndex: !prevSlide || !properties ? 0 : prevSlide.index + 1 < properties.length ? prevSlide.index + 1 : 0
            }
        else // Show next property
            return {
                type: TYPE_PROPERTY,
                index: !prevSlide ? 0 : prevSlide.type === TYPE_DIVIDER ? prevSlide.nextPropertyIndex : prevSlide.index + 1
            }
    }

    return (
        <Grid container sx={{ flexGrow: 1, height: '100vh' }}>
            {
                error
                    ?
                    <Grid
                        sx={{
                            flexGrow: 1,
                            alignContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            mx: 'auto',
                            textAlign: 'center'
                        }}
                        xs={12}
                        md={8}
                        container
                        direction="column"
                    >
                        <Typography variant="h6">Endereço inválido</Typography>
                    </Grid>
                    :
                    code && !code.validated && !presentation
                        ?
                        <Grid
                            sx={{
                                flexGrow: 1,
                                alignContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                mx: 'auto',
                                textAlign: 'center'
                            }}
                            xs={12}
                            md={8}
                            container
                            direction="column"
                        >
                            <Typography variant="h4" sx={{ mt: 'auto' }}>{code.code}</Typography>
                            <Typography variant="h6">Autenticação necessária!</Typography>
                            <Typography sx={{ mb: 'auto' }}>
                                Para aceder à apresentação aceda ao painel de administração e insira o código de autenticação. Uma vez validado, aguarde alguns segundos e a apresentação iniciar-se-á automaticamente.
                            </Typography>
                        </Grid>
                        :
                        (!presentation || !properties || properties.length === 0)
                            ?
                            <CircularProgress sx={{ mt: '30%', mx: 'auto' }} />
                            :
                            <Grid
                                sx={{
                                    position: 'relative',
                                    flexGrow: 1
                                }}
                                container
                            >
                                {
                                    // CLOCK FEEDBACK
                                    !transitioning && elapsed > 0 &&
                                    <Fade in={true} timeout={1000}>
                                        <CircularProgress
                                            sx={{
                                                position: 'absolute',
                                                bottom: 20,
                                                left: 20,
                                                zIndex: 2
                                            }}
                                            thickness={22}
                                            value={elapsed}
                                            variant="determinate"
                                            color="secondary"
                                        />
                                    </Fade>
                                }
                                { /** TRANSITION */}
                                <Fade appear={false} in={transitioning} timeout={1000} onEntered={() => {
                                    // When transition has entered, start fade out
                                    setTimeout(() => setTransitioning(false), [100]); // Defer otherwise fade out effect breaks
                                }}>
                                    <Grid
                                        sx={{
                                            backgroundColor: primaryColor,
                                            flexGrow: 1,
                                            height: '100vh',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            height: '100%',
                                            width: '100%',
                                            zIndex: 3
                                        }}
                                        container
                                    >
                                    </Grid>
                                </Fade>
                                { /** SLIDES */}
                                {
                                    // SLIDE DIVIVER
                                    slide && slide.type === TYPE_DIVIDER &&
                                    <Grid
                                        sx={{
                                            backgroundColor: primaryColor,
                                            flexGrow: 1,
                                            height: '100vh'
                                        }}
                                        container
                                    >
                                        <Fade in={true} timeout={4000}>
                                            <img
                                                src={process.env.REACT_APP_STATIC + '/static/assets/logow.png'}
                                                height="25%"
                                                width="auto"
                                                style={{ margin: 'auto' }}
                                            />
                                        </Fade>
                                    </Grid>
                                }
                                {
                                    // SLIDE IMAGE
                                    slide && slide.type === TYPE_PROPERTY &&
                                    <Grid container direction="row" wrap="nowrap" sx={{ position: 'relative' }}>
                                        <Grid
                                            sx={{
                                                backgroundImage: 'url(' + process.env.REACT_APP_STATIC + properties[slide.index].images[
                                                    Math.min(
                                                        properties[slide.index].images.length - 1,
                                                        presentation.maxPhotosPerProperty - 1,
                                                        Math.floor((elapsed / (100 / presentation.maxPhotosPerProperty)))
                                                    )
                                                ].image + ')',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'auto ' + Math.max(150 - elapsed / 4, 100) + '%', // Width auto, height variabe
                                                backgroundPosition: elapsed / 2 + '% 50%', // X, Y
                                                height: '100%',
                                                backgroundColor: primaryColor,
                                                transition: 'all .5s ease'
                                            }}
                                            xs={10}
                                            className="boxImageTriangleTV"
                                        />
                                        <Grid
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                right: 0,
                                                height: '100%',
                                                width: '35%'
                                            }}
                                        >
                                            <Grid
                                                sx={{
                                                    position: 'relative',
                                                    height: '100%',
                                                    width: '100%',
                                                    p: {
                                                        xs: 5,
                                                        tv: 10
                                                    }
                                                }}
                                                container
                                                direction="column"
                                                justifyContent="flex-end"
                                                alignItems="flex-end"
                                                wrap="nowrap"
                                            >
                                                {/** QR CODE */}
                                                <QRCode
                                                    style={{
                                                        height: '25%',
                                                        width: 'auto',
                                                        marginBottom: 'auto'
                                                    }}
                                                    value={process.env.REACT_APP_PUBLIC_URL + '/' + properties[slide.index].id}
                                                    bgColor={colors.pureWhite}
                                                    fgColor={primaryColor}
                                                />

                                                <Grid sx={{ textAlign: 'right', width: '90%' }} mt={'auto'}>

                                                    {/** Title */}
                                                    <Typography variant="h4" sx={{ fontWeight: 'bold', pl: 3 }}>
                                                        {properties[slide.index].title}
                                                    </Typography>

                                                    {/** Price */}
                                                    <Typography sx={{ fontWeight: 'bold' }} color='primary' variant='h5' mb={3}>
                                                        {properties[slide.index].priceHidden ? 'Sob consulta' : (properties[slide.index].price.toLocaleString()+'€')}
                                                    </Typography>

                                                    {/** Details 1 */}
                                                    <Grid container direction="column" wrap="nowrap">
                                                        <Grid container sx={{ ml: 'auto' }} justifyContent="flex-end" alignItems="flex-end">
                                                            <Typography variant="body2" sx={{ mr: 1, mb: .9 }}><HomeIcon fontSize="inherit" sx={{ mb: .5, verticalAlign: 'middle', color: primaryColor }} /> Ref.</Typography>
                                                            <Typography variant="h6" sx={{ fontWeight: 'normal' }}>{properties[slide.index].id}</Typography>
                                                            <Typography variant="body2" sx={{ mr: 1, ml: 3, mb: .9 }}><AttachFileIcon fontSize="inherit" sx={{ mb: .5, verticalAlign: 'middle', color: primaryColor }} /> Estado</Typography>
                                                            <Typography variant="h6" sx={{ fontWeight: 'normal' }}>{properties[slide.index].state}</Typography>
                                                        </Grid>
                                                        <Grid container sx={{ ml: 'auto' }} justifyContent="flex-end" alignItems="flex-end">
                                                            <Typography variant="body2" sx={{ mr: 1, mb: .9 }}><LocationOnIcon fontSize="inherit" sx={{ mb: .5, verticalAlign: 'middle', color: primaryColor }} /> Concelho</Typography>
                                                            <Typography variant="h6" sx={{ fontWeight: 'normal' }}>{properties[slide.index].parish.county.name}</Typography>
                                                        </Grid>
                                                        <Grid container sx={{ ml: 'auto' }} justifyContent="flex-end" alignItems="flex-end">
                                                            <Typography variant="body2" sx={{ mr: 1, mb: .9 }}><SquareFootIcon fontSize="inherit" sx={{ mb: .5, verticalAlign: 'middle', color: primaryColor }} /> Lote (m²)</Typography>
                                                            <Typography variant="h6" sx={{ fontWeight: 'normal' }}>{properties[slide.index].area}</Typography>
                                                            <Typography variant="body2" sx={{ mr: 1, ml: 3, mb: .9 }}><RoofingIcon fontSize="inherit" sx={{ mb: .5, verticalAlign: 'middle', color: primaryColor }} /> Útil (m²)</Typography>
                                                            <Typography variant="h6" sx={{ fontWeight: 'normal' }}>{properties[slide.index].areaconstructed}</Typography>
                                                        </Grid>
                                                        <Grid container sx={{ ml: 'auto' }} justifyContent="flex-end" alignItems="flex-end">
                                                            <Typography variant="body2" sx={{ mr: 1, mb: .9 }}><KingBedIcon fontSize="inherit" sx={{ mb: .5, verticalAlign: 'middle', color: primaryColor }} /> Quartos</Typography>
                                                            <Typography variant="h6" sx={{ fontWeight: 'normal' }}>{properties[slide.index].bedrooms}</Typography>
                                                            <Typography variant="body2" sx={{ mr: 1, ml: 3, mb: .9 }}><ShowerIcon fontSize="inherit" sx={{ mb: .5, verticalAlign: 'middle', color: primaryColor }} /> WCs</Typography>
                                                            <Typography variant="h6" sx={{ fontWeight: 'normal' }}>{properties[slide.index].bathrooms}</Typography>
                                                            {
                                                                properties[slide.index].garage &&
                                                                <>
                                                                    <Typography variant="body2" sx={{ mr: 1, ml: 3, mb: .9 }}><GarageIcon fontSize="inherit" sx={{ mb: .5, verticalAlign: 'middle', color: primaryColor }} /></Typography>
                                                                    <Typography variant="h6" sx={{ fontWeight: 'normal' }}>Garagem</Typography>
                                                                </>
                                                            }
                                                        </Grid>
                                                    </Grid>

                                                    {/** AGENT CONTACT DETAILS */}
                                                    <Typography sx={{ fontWeight: 'bold' }} color='primary' variant='h5' mt={3}>
                                                        {properties[slide.index].agent.name}
                                                    </Typography>
                                                    <Typography variant="h4" sx={{ fontWeight: 'bold', pl: 3 }}>
                                                        {properties[slide.index].agent.contact.toLocaleString()}
                                                    </Typography>

                                                    {/** AGENT PICTURE */}
                                                    <img
                                                        src={process.env.REACT_APP_STATIC + properties[slide.index].agent.image}
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: '-5%',
                                                            left: '-15%',
                                                            width: '45%',
                                                            height: 'auto',
                                                            borderRadius: '50%'
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
            }
        </Grid>
    );
}

export default PropertyTVPresentation;