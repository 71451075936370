import {
    Button,
    Tooltip,
    Typography,
    Badge,
    Grid
} from '@material-ui/core';

import {
    Event as EventIcon,
    Home as HomeIcon,
    Star as StarIcon,
    Euro as EuroIcon,
    KingBed as KingBedIcon,
    AttachFile as AttachFileIcon,
    LocationOn as LocationOnIcon,
    FilterList as FilterListIcon,
    SupervisorAccount as SupervisorAccountIcon,
    StickyNote2 as StickyNote2Icon,
    Archive as ArchiveIcon,
    DriveFileRenameOutline as DriveFileRenameOutlineIcon,
    Warning as WarningIcon,
    Error as ErrorIcon,
    Chat as ChatIcon,
    Description as DescriptionIcon,
    MarkUnreadChatAlt as MarkUnreadChatAltIcon,
    VisibilityOff as VisibilityOffIcon,
    Handshake as HandshakeIcon,
    AccountBalance,
    PublishedWithChanges,
    VisibilityOff
} from '@mui/icons-material';

import {
    getDictsLabelId,
    buildFilterSelect,
    buildFilterSlider,
    buildFilterSwitch,
    buildFilterText,
    validatorIsInteger
} from '../../../Components/Chavelusa/FormGroup/utils';

import { is_agent } from '../../../../Services/permissions';

import { reduceString } from '../../../utils';

import {
    PROPERTY_STATUS_ARCHIVED,
    PROPERTY_STATUS_DRAFT,
    PROPERTY_STATUS_REPROVED,
    PROPERTY_STATUS_PENDING
} from '../../../../Models/property';

import { propertyForm } from '../../../Components/Realstate/Property/Form/launcher';


/**
 * This variable defines the cols of the clients table
 */
export const getCols = (lowDenseMode) => {
    let cols_prepare = [];

    if (lowDenseMode) {
        cols_prepare.push({
            id: 'banner',
            label: 'Imagem de destaque',
            width: '20%'
        })
    }

    cols_prepare.push({
        id: 'created',
        label: 'Adicionado',
        sort: true
    });
    cols_prepare.push({
        id: 'id',
        label: 'Referência'
    });
    cols_prepare.push({
        id: 'title',
        label: 'Título'
    });
    if (!is_agent)
        cols_prepare.push({
            id: 'agent',
            label: 'Agente'
        });
    cols_prepare.push({
        id: 'price',
        label: 'Preço',
        sort: true
    });
    cols_prepare.push({
        id: 'status',
        label: 'Estado'
    });
    cols_prepare.push({
        id: 'operations',
        label: 'Ficha da propriedade'
    });
    cols_prepare.push({
        id: 'notes',
        label: 'Notas'
    });

    return cols_prepare;
}


/**
 * This method returns an array of react components, one for each column of the table
 * @param {Object} obj A client object fom the list retrieved from the /client_agents API endpoint
 * @returns {Component[]} One component per column of the table for the client row
 */
export const getRow = (obj, lowDenseMode) => {
    let row = [];
    // Banner
    if (lowDenseMode) {
        row.push(<>
            {obj.banner && <Grid
                p={10}
                m={-2}
                sx={{position: 'relative'}}
            >
                <Grid
                    sx={{
                        backgroundImage: 'url(' + process.env.REACT_APP_STATIC + obj.banner + ')',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        width: '100%', height: '100%',
                        position: 'absolute', right: 0, left: 0, top: 0, bottom: 0
                    }}
                ></Grid>
            </Grid>}
        </>);
    }
    // Created
    row.push(
        <>
            <EventIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
            {new Date(obj.created).toLocaleDateString('pt-PT')}
        </>
    );
    // Reference
    row.push(
        <>
            <HomeIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
            {obj.id}
        </>
    );
    // Title
    row.push(
        <>
            {obj.exclusive &&
                <Tooltip title="Propriedade oculta">
                    <VisibilityOff color="primary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                </Tooltip>
            }
            {obj.bankproperty &&
                <Tooltip title="Imóvel da banca">
                    <AccountBalance color="primary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                </Tooltip>
            }
            {obj.exchange &&
                <Tooltip title="Aceita permuta">
                    <PublishedWithChanges color="primary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                </Tooltip>
            }
            {obj.title}
        </>
    );
    // Agente
    if (!is_agent) {
        row.push(
            <>
                <SupervisorAccountIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                {obj.agent.name}
            </>
        );
    }
    // Price
    row.push(<>{obj.price.toString().toLocaleString()}€ 
        {obj.negociable &&
            <Tooltip title="Negociável">
                <HandshakeIcon color="primary" fontSize="inherit" sx={{ ml: 1, verticalAlign: 'middle' }} />
            </Tooltip>
        }
        {obj.priceHidden &&
            <Tooltip title="Preço sob consulta">
                <VisibilityOffIcon color="primary" fontSize="inherit" sx={{ ml: 1, verticalAlign: 'middle' }} />
            </Tooltip>
        }
    </>);
    // Status
    if (obj.status === PROPERTY_STATUS_ARCHIVED) {
        row.push(<Tooltip title="Propriedade arquivada"><ArchiveIcon color="secondary" /></Tooltip>);
    } else {
        row.push(
            <>
                {obj.status === PROPERTY_STATUS_DRAFT && <Tooltip title="Rascunho"><DriveFileRenameOutlineIcon color="secondaray" /></Tooltip>}
                {obj.status === PROPERTY_STATUS_PENDING && <Tooltip title="Aguarda aprovação"><WarningIcon color="warning" /></Tooltip>}
                {obj.status === PROPERTY_STATUS_REPROVED && <Tooltip title="Reprovada"><ErrorIcon color="error" /></Tooltip>}
                {obj.forms_pending && <Tooltip title="Formulários de contacto pendentes"><ChatIcon color="warning" /></Tooltip>}
                {obj.has_errors && <Tooltip title="Ficha da propriedade incompleta"><DescriptionIcon color="warning" /></Tooltip>}
                {obj.messages_pending && <Tooltip title="Mensagens pendentes na conversa da propriedade"><MarkUnreadChatAltIcon color="error" /></Tooltip>}
            </>
        );
    }
    // Operations
    row.push(
        <a href={process.env.REACT_APP_ADMIN_URL + '/propriedades/' + obj.id}>
            <Button
                href={'/propriedades/' + obj.id}
                variant="outlined"
            >
                Aceder
            </Button>
        </a>
    );
    // Notes
    row.push(
        <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{obj.notes ? obj.notes : ''}</div>}>
            <Typography>
                {
                    obj.notes &&
                    <StickyNote2Icon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                }
                {reduceString(obj.notes, 30)}
            </Typography>
        </Tooltip>
    );
    return row;
}

/**
 * Builds the clients table filters, given data retrieved from the client_agents/filters API endpoint
 * @param {Object} data The object returned from the API
 * @returns {Object[]} filters An aray of objects that define the filters fields
 */
export const buildFilters = (data) => {
    let filters = [];

    filters.push(
        buildFilterSwitch('Arquivada', 'archived', false, null)
    );

    filters.push(
        buildFilterSwitch('Oculta', 'exclusive', false, null)
    );
    filters.push(
        buildFilterSwitch('Imóvel da banca', 'bankproperty', false, null)
    );
    filters.push(
        buildFilterSwitch('Aceita permuta', 'exchange', false, null)
    );

    filters.push(
        buildFilterSwitch('Mensagens pendentes', 'pending_messages', false, null)
    );

    filters.push(
        buildFilterSwitch('Formulários pendentes', 'pending_forms', false, null)
    );

    filters.push(
        buildFilterSwitch('Erros na ficha', 'pending_errors', false, null)
    );

    if ('agents' in data && !is_agent) {
        filters.push(
            buildFilterSelect(
                'Agente',
                'select',
                'agent',
                data.agents.map(c => { return { label: c.name, id: c.id } }),
                null, false, null, undefined, true
            )
        )
    }

    if ('status' in data) {
        filters.push(
            buildFilterSelect(
                'Estado',
                'multipleselectchips',
                'status__in',
                data.status.filter(s => s.id !== 'Q' /* Remove archived */).map(s => { return { label: s.label, id: s.id } }),
                [{ id: 'archived', values: [false, undefined, null] }]
            )
        )
    }

    if ('states' in data) {
        filters.push(
            buildFilterSelect(
                'Finalidade',
                'select',
                'state',
                data.states.map(s => { return { label: s.label, id: s.id } })
            )
        )
    }

    if ('counties' in data) {
        filters.push(
            buildFilterSelect(
                'Concelho',
                'select',
                'parish__county',
                data.counties.map(c => { return { label: c.name, id: c.id } }),
                null, false, null, undefined, true
            )
        )
    }

    if ('types' in data) {
        filters.push(
            buildFilterSelect(
                'Tipo de propriedade',
                'select',
                'type',
                data.types.map(t => { return { label: t.name, id: t.id } })
            )
        )
    }

    if ('features' in data) {
        filters.push(
            buildFilterSelect(
                'Características',
                'multipleselect',
                'features',
                data.features.map(f => { return { label: f.name, id: f.id } }),
                null, false, null, undefined, true
            )
        )
    }

    if ('filters' in data) {
        filters.push(buildFilterText(
            `Área maior que (entre ${data.filters.areamin} e ${data.filters.areamax})`, 
            'area__gte',
            false,
            (v) => validatorIsInteger(v) && parseInt(v)>=data.filters.areamin && parseInt(v)<=data.filters.areamax,
            undefined,
            'number'
        ));
        filters.push(buildFilterText(
            `Área menor que (entre ${data.filters.areamin} e ${data.filters.areamax})`, 
            'area__lte',
            false,
            (v) => validatorIsInteger(v) && parseInt(v)>=data.filters.areamin && parseInt(v)<=data.filters.areamax,
            undefined,
            'number'
        ));
        filters.push(buildFilterText(
            `Preço maior que (entre ${data.filters.pricemin} e ${data.filters.pricemax})`, 
            'price__gte',
            false,
            (v) => validatorIsInteger(v) && parseInt(v)>=data.filters.pricemin && parseInt(v)<=data.filters.pricemax,
            undefined,
            'number'
        ));
        filters.push(buildFilterText(
            `Preço menor que (entre ${data.filters.pricemin} e ${data.filters.pricemax})`, 
            'price__lte',
            false,
            (v) => validatorIsInteger(v) && parseInt(v)>=data.filters.pricemin && parseInt(v)<=data.filters.pricemax,
            undefined,
            'number'
        ));
        filters.push(
            buildFilterSlider(
                'Quartos (min)',
                'bedrooms__gte',
                data.filters.bedroomsmin,
                data.filters.bedroomsmax
            )
        )
        filters.push(
            buildFilterSlider(
                'Casas de banho (min)',
                'bathrooms__gte',
                data.filters.bathroomsmin,
                data.filters.bathroomsmax
            )
        )
    }

    console.log("filters", filters);

    return filters;
}

/**
 * This methos builds the tutorial object
 */
const buildTutorial_partial = [
    {
        element: '.property_list table',
        intro: is_agent ? 'Nesta página tem acesso à lista das suas propriedades.' : 'Nesta página tem acesso à lista das propriedades.'
    },
    {
        element: '.property_list table thead th:first-of-type',
        intro: 'Ao carregar nas colunas "Adicionado" ou "Preço", pode ordenar por esses atributos, invertendo a ordenação ao segundo clique.'
    },
    {
        element: `.property_list table tbody tr:first-of-type td:nth-of-type(${is_agent ? 4 : 5})`,
        intro: 'O estado da propriedade é apresentado através de símbolos, podendo consultar o seu significado ao passar o rato por cima.'
    },
    {
        element: `.property_list table tbody tr:first-of-type td:nth-of-type(${is_agent ? 5 : 6})`,
        intro: 'Para aceder à ficha da propriedade basta carregar neste botão.'
    },
    {
        element: '.property_list .pagination-wrapper',
        intro: 'A navegação entre páginas é feita na parte inferior.'
    },
    {
        element: '.property_list .table-search',
        intro: 'Pode utilizar este campo para pesquisar pelo título, referência ou localização.'
    },

    {
        element: '.property_list .table-filter',
        intro: 'Pode filtrar a tabela de acordo com as características que procura.'
    },
    {
        element: '.property_list .table-add-new',
        intro: 'Por fim, clique no botão + para adicionar uma nova propriedade.'
    }
]
export const buildTutorial = buildTutorial_partial;

/**
 * Builds the export objects for SelectTable
 * @param {String} baseUrl 
 */
export const generate_exports = (baseUrl) => {
    return []
}

// ADD NEW
export const addNew = (goBack, updateEntity, onChange) => {
    return propertyForm(undefined, goBack, updateEntity, onChange);
}

// FLAGS 
export const enableSelect = false;
export const enableSearch = true;