import React, { useState, useEffect } from "react";
import { styled, useTheme } from '@mui/material/styles';


import {
    Grid,
    Toolbar,
    IconButton,
    Typography,
    AppBar as MuiAppBar,
    Tooltip,
    Avatar,
    Menu,
    MenuItem
} from '@mui/material';

import {
    Menu as MenuIcon,
    Logout as LogoutIcon,
    Person,
    AccountCircle
} from '@mui/icons-material';

import {
    getCookie,
    requestGET
} from '../../utils';
import moment from "moment";
import { useNavigate } from "react-router";
import TimeService from "../../../Services/time";

const drawerWidth = 0;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Navbar = ({ sidebarOpen, setSidebarOpen }) => {


    const [user, setUser] = useState(undefined);

    useEffect(() => {

        requestGET(process.env.REACT_APP_API_URL + '/login/user/', null, true)
            .then(response => {
                console.log("GOT user", response);
                setUser(response);
            })
            .catch(err => {
                if ((err.status === 404 || err.name === 'TypeError') && window.location.pathname != '/manutencao') {
                    window.location.href = "/manutencao?goBackTo=" + window.location.pathname;
                }
            });
    }, []);

    // Menu
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="relative" open={sidebarOpen}>
            <Toolbar sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => setSidebarOpen(old => !old)}
                    edge="start"
                    sx={{ mr: 2, ...(sidebarOpen && { display: 'none' }) }}
                >
                    <Tooltip title="Menu">
                        <MenuIcon />
                    </Tooltip>
                </IconButton>
                <Grid container width="auto" mr="auto">
                    <img
                        src={process.env.REACT_APP_SERVER_URL + '/static/elements/logo.png'}
                        height="48px"
                        width="auto"
                    />
                    {
                        getCookie("env") === "TEST" &&
                        <Typography variant="h6" noWrap component="div" ml={2} sx={{ display: { xs: 'none', sm: 'inline-block' }, my: 'auto' }}>
                            Ambiente de testes
                        </Typography>
                    }
                </Grid>

                <Grid container width="auto" ml='auto' sx={{ alignItems: 'center' }}>
                    {
                        user &&
                        <>
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={!!anchorEl ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={!!anchorEl ? 'true' : undefined}
                            >
                                <Tooltip title="Definições de conta">
                                    <Avatar
                                        sx={{
                                            backgroundColor: 'white',
                                            boxShadow: `0 0 0 2px ${getCookie('primary_color')} inset`,
                                            border: `1px solid white`,
                                            transition: '.25s ease all',
                                            '&:hover': {
                                                borderWidth: '2px',
                                                cursor: 'pointer'
                                            },
                                            color: getCookie('primary_color')
                                        }}
                                    ><Person /></Avatar>
                                </Tooltip>
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={!!anchorEl}
                                onClose={handleClose}
                            >
                                <MenuItem disabled>
                                    {TimeService.getGreeting()}, {user.user.first_name}!
                                </MenuItem>
                                <MenuItem onClick={() => window.location.href = process.env.REACT_APP_ADMIN_URL + '/login/conta'}>
                                    <AccountCircle sx={{ mr: 1 }} /> A minha conta
                                </MenuItem>
                                <MenuItem onClick={() => window.location.href = process.env.REACT_APP_ADMIN_URL + '/login/logout'}>
                                    <LogoutIcon sx={{ mr: 1 }} /> Terminar sessão
                                </MenuItem>
                            </Menu>

                        </>
                    }

                </Grid>



            </Toolbar>
        </AppBar >
    );
}

export default Navbar;