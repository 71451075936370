import {
    Button,
    Tooltip,
    Typography,
    Badge,
    Grid
} from '@material-ui/core';

import {
    Event as EventIcon,
    Home as HomeIcon,
    Star as StarIcon,
    Euro as EuroIcon,
    KingBed as KingBedIcon,
    AttachFile as AttachFileIcon,
    LocationOn as LocationOnIcon,
    FilterList as FilterListIcon,
    SupervisorAccount as SupervisorAccountIcon,
    StickyNote2 as StickyNote2Icon,
    Archive as ArchiveIcon,
    DriveFileRenameOutline as DriveFileRenameOutlineIcon,
    Warning as WarningIcon,
    Error as ErrorIcon,
    Chat as ChatIcon,
    Description as DescriptionIcon,
    MarkUnreadChatAlt as MarkUnreadChatAltIcon
} from '@mui/icons-material';

import {
    getDictsLabelId,
    buildFilterSelect,
    buildFilterSlider,
    buildFilterSwitch
} from '../../../../Components/Chavelusa/FormGroup/utils';

import { is_agent, canReadGenericData } from '../../../../../Services/permissions';

import { reduceString } from '../../../../utils';

import { propertyForm } from '../../../../Components/Realstate/Property/Form/launcher';


/**
 * This variable defines the cols of the clients table
 */
const cols_prepare = [
    {
        id: 'created',
        label: 'Adicionado',
        sort: true
    },
    {
        id: 'filters',
        label: 'Filtros'
    },
    {
        id: 'operations',
        label: 'Operações'
    },
    {
        id: 'notes',
        label: 'Notas'
    },
];

export const cols = (lowDenseMode) => cols_prepare;

/**
 * This method returns an array of react components, one for each column of the table
 * @param {Object} obj A client object fom the list retrieved from the /client_agents API endpoint
 * @returns {Component[]} One component per column of the table for the client row
 */
export const getRow = (obj, reproduce, edit) => {
    let row = [];
    // Created
    row.push(
        <>
            <EventIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
            {new Date(obj.created).toLocaleDateString('pt-PT')}
        </>
    );
    // Filters
    row.push(
        <>
            {
                obj.filterProperties && obj.filterProperties.length > 0 &&
                <Grid>
                    <HomeIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    {obj.filterProperties.map(fp => fp.property.id).join(' | ')}
                </Grid>
            }
            {
                obj.filterCounties && obj.filterCounties.length > 0 &&
                <Grid>
                    <LocationOnIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    {obj.filterCounties.map(fc => fc.county.name).join(' | ')}
                </Grid>
            }
            {
                obj.filterStates && obj.filterStates.length > 0 &&
                <Grid>
                    <AttachFileIcon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                    {obj.filterStates.map(fs => fs.state_display).join(' | ')}
                </Grid>
            }
        </>
    );
    // Operations
    row.push(
        <Grid container direction="row">
            <Button
                onClick={edit}
                sx={{ mr: 1, mb: 1 }}
                variant="outlined"
            >
                Editar
            </Button>
            <Button
                onClick={reproduce}
                variant="contained"
                sx={{ mb: 'auto' }}
            >
                Apresentar
            </Button>
        </Grid>
    );
    // Notes
    row.push(
        <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{obj.notes ? obj.notes : ''}</div>}>
            <Typography>
                {
                    obj.notes &&
                    <StickyNote2Icon color="secondary" fontSize="inherit" sx={{ mr: 1, verticalAlign: 'middle' }} />
                }
                {reduceString(obj.notes, 30)}
            </Typography>
        </Tooltip>
    );
    return row;
}

/**
 * Builds the clients table filters, given data retrieved from the client_agents/filters API endpoint
 * @param {Object} data The object returned from the API
 * @returns {Object[]} filters An aray of objects that define the filters fields
 */
export const buildFilters = (data) => {
    let filters = [];

    return undefined;
}

/**
 * This methos builds the tutorial object
 */
const buildTutorial_partial = []
export const buildTutorial = buildTutorial_partial;

/**
 * Builds the export objects for SelectTable
 * @param {String} baseUrl 
 */
export const generate_exports = (baseUrl) => {
    return []
}

// ADD NEW
export const addNew = (goBack, updateEntity, onChange) => {
    return propertyForm(undefined, goBack, updateEntity, onChange);
}

// FLAGS 
export const enableSelect = false;
export const enableSearch = true;