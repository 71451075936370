import React, { useState, useEffect } from "react";
import { Outlet } from 'react-router-dom';

import { 
    CircularProgress,
    Grid,
    Link,
    Typography
} from '@mui/material';

import Sidebar from '../Sidebar';
import Navbar from '../Navbar';

import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';

import './index.css';
import { USER_ID } from "../../../Services/permissions";
import { redirectToLoginLocal } from "../../utils";

const MainLayout = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);

    if (!USER_ID) {
        redirectToLoginLocal();
        return ( <Grid 
            container 
            direction="column"
            sx={{flexGrow: 1}}
            wrap="nowrap"
            alignItems={'center'}
            justifyContent={'center'}
        >
            <CircularProgress sx={{ mb: 3 }} />
            <Grid xs={11} md={5} xl={3} sx={{textAlign: 'center', flexBasis: '0!important', flexShrink: 1}}>
                <Typography variant='h4' sx={{ fontWeight: 500 }}>A autenticar...</Typography>
                <Typography>Deverá ser redirecionado para a página de autenticação dentro de momentos, caso contrário, clique <Link href="#" onClick={redirectToLoginLocal}>aqui</Link>.</Typography>
            </Grid>
        </Grid>)
    }

    return (
        <Grid 
            container 
            direction="row"
            sx={{flexGrow: 1}}
            wrap="nowrap"
        >
            <Sidebar 
                open={sidebarOpen}
                setOpen={setSidebarOpen}
            />
            <Grid 
                container 
                direction="column"
                sx={{flexGrow: 1}}
                wrap="nowrap"
            >
                <Navbar 
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                />
                <Grid
                    sx={{p: 5, display: {xs: 'none', md: 'block'}}}
                    container
                >
                    <Outlet/>
                </Grid>
                <Grid
                    sx={{
                        p: 5, 
                        display: {xs: 'flex', md: 'none'}, 
                        flexDirection: 'column',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <PhonelinkOffIcon fontsize="large" />
                    <Typography variant="h6" align="center">
                        Versão mobile indisponível
                    </Typography>
                    <Typography variant="body2" align="center">
                        Estamos a trabalhar no desenvolvimento da versão mobile do painel de administração. Será contactado quando estiver disponível.
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default MainLayout;