import { Grid, Typography } from "@mui/material";
import { colors } from "../../../utils";


const StatsElement = ({ icon, number, label }) => {
    return (<Grid item>
        <Typography variant="h3" sx={{lineHeight: 1, fontWeight: 'bold', color: colors.primary, mb: 0}}>{number} {icon}</Typography>
        <Typography>{label}</Typography>
    </Grid>);
};

export default StatsElement;