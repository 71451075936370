import React from "react";
import { Navigate } from "react-router-dom";

import MainLayout from "./Views/Layouts/MainLayout";
import CleanLayout from "./Views/Layouts/CleanLayout";

import Homepage from "./Views/Pages/Homepage";
import ClientList from "./Views/Pages/CRM/ClientList";
import ClientPage from "./Views/Pages/CRM/ClientPage";
import ClientDuplicatePage from "./Views/Pages/CRM/ClientDuplicatePage";
import Maintenance from './Views/Pages/Static/Maintenance';
import PropertyList from "./Views/Pages/Realstate/PropertyList";
import PropertyPage from './Views/Pages/Realstate/PropertyPage';
import PropertyTVPresentation from "./Views/Pages/Realstate/TV/Presentation";
import PresentationList from "./Views/Pages/Realstate/TV/PresentationList";
import PropertyAdvertsList from "./Views/Pages/ExternalApps/PropertyList";

const routes = [
	{
		path: "/",
		element: <MainLayout />,
		children: [
            { path: "/", element: <Homepage /> },
			// CRM
			{ path: "clientes", element: <ClientList /> },
			{ path: "clientes/duplicados", element: <ClientList duplicates={true} /> },
			{ path: "clientes/duplicados/:id", element: <ClientDuplicatePage /> },
			{ path: "clientes/:id", element: <ClientPage />},
			// Realstate
			{ path: 'propriedades', element: <PropertyList />},
			{ path: 'propriedades/:id', element: <PropertyPage />},
			{ path: 'apresentacoes', element: <PresentationList />},
			// External apps
			{ path: 'anuncios', element: <PropertyAdvertsList />},
			
		],
	},
	{
		path: "/",
		element: <CleanLayout />,
		children: [
			// Realstate
			{ path: 'tv/:id', element: <PropertyTVPresentation />},
			// Maintenance
			{ path: "manutencao", element: <Maintenance />},
		],
	}
];

export default routes;
