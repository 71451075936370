import { Avatar, Chip, Grid, Link, Typography } from "@mui/material";


const NotificationElement = ({ icon, title, description, pills, url }) => {
    return (<Grid container direction={'row'} alignItems={'center'} sx={{gap: 1, py: 1}} flexWrap={'nowrap'}>
        <Link href={url}>
            <Avatar sx={{bgcolor: 'error', width: 36, height: 36}} size="small">
                {icon}
            </Avatar>
        </Link>
        <Grid item sx={{p: .5}}>
            <Typography variant="h6" sx={{lineHeight: 1, fontWeight: 'normal'}}>{title}</Typography>
            <Typography>{description}</Typography>
            <Grid container direction={'row'} gap={.25} mt={.5}>
                {pills && pills.map(p => p && p.label && <Chip size="small" label={p.label} variant="outlined" />)}
            </Grid>
        </Grid>
    </Grid>);
};

export default NotificationElement;