import React, { useState, useEffect, createElement } from "react";

import {
    Grid,
    Typography,
    Button,
    CircularProgress
} from '@mui/material';

import {
    DateHelper,
    requestGET,
    requestWithBody,
    handleRequestError
} from '../../../utils';

import FilesUploader from "../FilesUploader";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

/**
 * This component allows to manage the creation/edition/deletion of several instances
 * 
 * @param {Array} instances         List of objects already loaded
 * 
 * @param {String} title            Window title
 * @param {Boolean} upload          If false, uploader is disabled, only preview available
 * @param {Integer} uploadId        Id of file attribute to be sent in upload POST body
 * @param {String} uploadUrl        URL to send POST request to 
 * @param {Object} uploadInitialBody    Body to be sent in addition to {<uploadId>: <FileUploaded>}
 * @param {String} editUrlAppend    String to be used in PATCH url: <uploadUrl>/<instance.id>?<editUrlAppend>
 * @param {String} reorderUrl       If defined, reorder will be possible and PATCH request will be done to endpoint with
 *                                      [{id:<Integer>, order:<Integer:zeroBased>}, ...]
 * @param {String} categoriesUrl    URL to GET categories to classify instances (passed to banner as prop)
 * @param {Component} banner        Component that receives the following props
 *                                      instance:Object, updateElement:Method, setNofity:Method, editionMode:Boolean, categories:List, editURL: String
 * 
 * @param {Method} updateElement    Method to update instance               (instance:Object, remove:Boolean)
 * @param {Method} close            Method to close file management window
 * @param {Method} notify           Method to pop up notification           (message:String, error:Boolean)
 * 
 */
const FilesUploaderManagement = ({
    instances, // Objects
    title, upload, uploadId, uploadUrl, uploadInitialBody, editUrlAppend, reorderUrl, categoriesUrl, banner, extraBannerProps={}, // Settings
    updateElement, close, notify // Methods
}) => {

    const [localInstances, setLocalInstances] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        // Initialize local instances with instances given
        setLocalInstances(instances);

        // Get categories
        if (categoriesUrl) {
            requestGET(categoriesUrl)
                .then(response => {
                    console.log("GOT categories", response);
                    setCategories(response);
                });
        }
    }, []);

    const instanceUpload = (instance, upload_index) => {
        setLocalInstances(old => [...old, instance]);
        updateElement(instance, false, upload_index===0); // ReloadInstance (property) because banner might have changed (only once needed)
        notify("O carregamento foi bem sucedido");
    }

    const getBanner = (value) => {
        return createElement(banner, {
            instance: value,
            updateElement: (instance, remove) => {
                // Update internal state and propagate changes upper in teh hierarchy
                if (remove)
                    setLocalInstances(old => old.filter(o => o.id !== instance.id))
                else
                    setLocalInstances(old => old.map(o => o.id === instance.id ? instance : o))
                updateElement(instance, remove, true); // ReloadInstance (property) because image can be deleted and banner will change
            },
            notify: notify,
            categories: categories,
            editionMode: true,
            editURL: uploadUrl + value.id + editUrlAppend,
            small: reorderUrl ? true : false,
            ...extraBannerProps
        })
    }

    // SORTABLE 
    const [submitting, setSubmitting] = useState(false);

    const SortableItem = SortableElement(({ value }) => getBanner(value));

    const SortableList = SortableContainer(({ items }) => {
        return (
            <Grid container>
                {items.map((value, index) => (
                    <SortableItem key={`item-${value.id}`} index={index} value={value} disabled={!reorderUrl || submitting} />
                ))}
            </Grid>
        );
    });

    const updateOrder = (oldIndex, newIndex) => {
        if (oldIndex === newIndex) return;
        setSubmitting(true);

        let reordered = [...localInstances];
        const element = reordered.splice(oldIndex, 1)[0];
        reordered.splice(newIndex, 0, element);

        requestWithBody("PATCH", reorderUrl, reordered.map((instance, index) => {
            return { id: instance.id, order: index }
        })).then(response => {
            console.log("REORDER SUCCESS", response);
            setLocalInstances(old => old.map((instance, old_index) => {
                instance.order = response[instance.id];
                updateElement(instance, false, old_index===0); // ReloadInstance (property) because banner might have changed (only once needed)
                return instance;
            }).sort((a, b) => a.order - b.order));
            notify("Elementos reordenados com sucesso");
        }).catch(error => {
            handleRequestError(
                error,
                [],
                "Error reordering instances",
            ).then(e => {
                notify("Ocorreu um erro, tente novamente", true);
            });
        }).finally(() => {
            setSubmitting(false);
        });
    }

    return (
        <Grid sx={{ width: '100%' }}>
            {
                submitting &&
                <Grid container
                    sx={{
                        backgroundColor: 'rgb(0,0,0,.8)',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        left: 0,
                        zIndex: 2,
                        height: '100%'
                    }}
                >
                    <Grid container direction="column" sx={{ my: 'auto', mx: 'auto' }}>
                        <Typography align="center" color="white">A guardar alterações...</Typography>
                        <CircularProgress sx={{ mx: 'auto' }} />
                    </Grid>
                </Grid>
            }

            <Typography variant="h6" mb={reorderUrl ? 1 : 3}>
                {title}
            </Typography>

            {
                reorderUrl &&
                <Typography mb={3}>Arraste os cartões para reordenar os elementos.</Typography>
            }

            {
                upload &&
                <FilesUploader
                    id={uploadId}
                    url={uploadUrl}
                    filesAttr={uploadId}

                    initialFiles={[]}
                    maxFiles={99}
                    update={instanceUpload}
                    allowDelete={false}

                    method={"POST"}
                    initialBody={uploadInitialBody}
                />
            }


            {
                localInstances.length > 0 && (!categoriesUrl || categories) &&
                <SortableList
                    items={localInstances}
                    onSortEnd={(changes) => updateOrder(changes.oldIndex, changes.newIndex)}
                    axis='xy'
                />
            }


            {
                <Button
                    color="primary"
                    variant={localInstances.length === instances.length ? "outlined" : "contained"}
                    sx={{ width: '100%', my: 3 }}
                    onClick={() => {
                        close();
                    }}
                >
                    Concluir
                </Button>
            }
        </Grid>
    );
}

export default FilesUploaderManagement;