import React, { useState, useEffect } from "react";

import {
    Container,
    Button,
    Grid,
    Typography,
    CircularProgress
} from '@material-ui/core';

import {
    requestGET,
    handleRequestError,
    getCookie
} from '../../utils';
import TimeService from "../../../Services/time";
import { AttachFile, Chat, Email, Event, Facebook, FacebookOutlined, Home, LinkedIn, Person, Pinterest, TaskAlt, Telegram, Twitter, WhatsApp, X } from "@mui/icons-material";
import NotificationElement from "../../Components/Chavelusa/NotificationElement";
import { is_admin } from "../../../Services/permissions";
import StatsElement from "../../Components/Chavelusa/StatsElement";

const Homepage = () => {

    const [user, setUser] = useState(null);


    const [messages, setMessages] = useState(null);
    const [messagesCount, setMessagesCount] = useState(null);
    const [pPending, setPPending] = useState(null);
    const [pPendingCount, setPPendingCount] = useState(null);

    const [cPending, setCPending] = useState(null);
    const [cPendingCount, setCPendingCount] = useState(null);

    const [visits, setVisits] = useState(null);
    const [visitsCount, setVisitsCount] = useState(null);

    const [stats, setStats] = useState({});

    const loadingMonitor = [true, true, true, true, true, false];
    const [loading, setLoading] = useState(false);

    const updateMonitor = (index, status) => {
        loadingMonitor[index] = status;
        setLoading(loadingMonitor.some(l => l));
    }

    useEffect(() => {
        // Load user
        requestGET(process.env.REACT_APP_API_URL + '/login/user/')
            .then(response => {
                setUser(response.user);

            }).catch(error =>
                handleRequestError(
                    error,
                    [],
                    "Error getting user",
                )
            );

        // Messages pending
        requestGET(process.env.REACT_APP_API_URL + '/realstate/properties/hasMessagesPending')
            .then(data => {
                setMessages(data.properties.sort((a, b) => new Date(a.lastMessage) < new Date(b.lastMessage)).slice(0, Math.min(10, data.properties.length))); // Most recent first
                setMessagesCount(data.properties.length);
            })
            .finally(() => updateMonitor(0, false))


        // Properties pending 
        requestGET((is_admin
            ? process.env.REACT_APP_API_URL + '/realstate/properties/?status__in=P&ordering=-created'
            : process.env.REACT_APP_API_URL + '/realstate/properties/?status__in=R,D&ordering=-created'
        )).then(data => {
            setPPending(data.results);
            setPPendingCount(data.count);
        })
            .finally(() => updateMonitor(1, false))

        // Clients pending
        requestGET(process.env.REACT_APP_API_URL + '/crm/client_agents/?type=unclassified&ordering=-created') // Most recent first
            .then(data => {
                setCPending(data.results);
                setCPendingCount(data.count);
            })
            .finally(() => updateMonitor(2, false))

        // Visits
        requestGET(process.env.REACT_APP_API_URL + '/crm/visits/future')
            .then(data => {
                setVisits(data.sort((a, b) => new Date(a.moment) > new Date(b.moment)).slice(0, Math.min(10, data.length))); // Oldest first (the closest in time)
                setVisitsCount(data.length);
            })
            .finally(() => updateMonitor(3, false))

        // Stats realstate
        requestGET(process.env.REACT_APP_API_URL + '/realstate/stats/global')
            .then(data => {
                setStats(old => ({ ...old, ...data }));
            })
            .finally(() => updateMonitor(4, false))

        // Stats crm
        requestGET(process.env.REACT_APP_API_URL + '/crm/client_agents/stats')
            .then(data => {
                setStats(old => ({ ...old, ...data }));
            })
            .finally(() => updateMonitor(5, false))
    }, []);

    return (
        <Grid>
            <Grid container direction={'row'}>
                {
                    user &&
                    <Grid item>
                        <Typography variant='h4' sx={{ fontWeight: 500 }}>{TimeService.getGreeting()}, {user.first_name}!</Typography>
                        <Typography>Seja bem-vindo/a ao portal de administração da <span style={{ color: getCookie('primary_color'), fontWeight: 500 }}>Chave Lusa</span>!</Typography>
                    </Grid>
                }
                {
                    loading && <CircularProgress sx={{ mt: 'auto', ml: 'auto' }} />
                }
            </Grid>

            <Grid container direction={'row'} mt={3} gap={2} flexWrap={'nowrap'}
                sx={{ maxHeight: '50vh', overflow: 'hidden' }}
            >
                <Grid container sx={{ backgroundColor: 'white' }} direction={'column'} p={3} xs={3} flexWrap={'nowrap'}>
                    <Typography variant="h6" mb={0}>Visitas
                        {visitsCount && visitsCount > 0 ? <span style={{ marginLeft: '8px' }}>({visitsCount})</span> : null}
                    </Typography>
                    {
                        !loading && (!visitsCount || visitsCount.length === 0) &&
                        <Typography sx={{ mt: 1 }} color={'secondary'}><TaskAlt sx={{ verticalAlign: '-2px' }} fontSize="inherit" /> Tudo em dia</Typography>
                    }
                    {
                        !loading && visits &&
                        <Grid sx={{ overflowY: 'auto' }}>
                            {visits.map(v => <NotificationElement
                                icon={<Event fontSize="inherit" />}
                                title={new Date(v.moment).toLocaleDateString('pt-PT', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
                                pills={[
                                    { label: v.client.name },
                                    { label: v.property.id + ', ' + v.property.parish.county.name },
                                    (is_admin ? { label: v.client.agent.name } : null)
                                ]}
                                url={'/clientes/' + v.client.id}
                            />)}
                        </Grid>
                    }
                </Grid>

                <Grid container sx={{ backgroundColor: 'white' }} direction={'column'} p={3} xs={3} flexWrap={'nowrap'}>
                    <Typography variant="h6" mb={0}>Mensagens
                        {messagesCount && messagesCount > 0 ? <span style={{ marginLeft: '8px' }}>({messagesCount})</span> : null}
                    </Typography>
                    {
                        !loading && (!messages || messages.length === 0) &&
                        <Typography sx={{ mt: 1 }} color={'secondary'}><TaskAlt sx={{ verticalAlign: '-2px' }} fontSize="inherit" /> Tudo em dia</Typography>
                    }
                    {
                        !loading && messages &&
                        <Grid sx={{ overflowY: 'auto' }}>
                            {messages.map(m => <NotificationElement
                                icon={<Chat fontSize="inherit" />}
                                title={m.lastSender}
                                pills={[
                                    { label: m.id },
                                    { label: new Date(m.lastMessage).toLocaleDateString('pt-PT', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }) }
                                ]}
                                url={'/propriedades/' + m.id}
                            />)}
                        </Grid>
                    }
                </Grid>

                <Grid container sx={{ backgroundColor: 'white' }} direction={'column'} p={3} xs={3} flexWrap={'nowrap'}>
                    <Typography variant="h6" mb={0}>Propriedades pendentes
                        {pPendingCount && pPendingCount > 0 ? <span style={{ marginLeft: '8px' }}>({pPendingCount})</span> : null}
                    </Typography>
                    {
                        !loading && (!pPending || pPending.length === 0) &&
                        <Typography sx={{ mt: 1 }} color={'secondary'}><TaskAlt sx={{ verticalAlign: '-2px' }} fontSize="inherit" /> Tudo em dia</Typography>
                    }
                    {
                        !loading && pPending &&
                        <Grid sx={{ overflowY: 'auto' }}>
                            {pPending.map(p => <NotificationElement
                                icon={<Home fontSize="inherit" />}
                                title={p.title}
                                pills={[
                                    { label: new Date(p.created).toLocaleDateString('pt-PT', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }) },
                                    (is_admin ? { label: p.agent.name } : null)
                                ]}
                                url={'/propriedades/' + p.id}
                            />)}
                        </Grid>
                    }
                </Grid>

                <Grid container sx={{ backgroundColor: 'white' }} direction={'column'} p={3} xs={3} flexWrap={'nowrap'}>
                    <Typography variant="h6" mb={0}>Clientes pendentes
                        {cPendingCount && cPendingCount > 0 ? <span style={{ marginLeft: '8px' }}>({cPendingCount})</span> : null}
                    </Typography>
                    {
                        !loading && (!cPendingCount || cPendingCount.length === 0) &&
                        <Typography sx={{ mt: 1 }} color={'secondary'}><TaskAlt sx={{ verticalAlign: '-2px' }} fontSize="inherit" /> Tudo em dia</Typography>
                    }
                    {
                        !loading && cPending &&
                        <Grid sx={{ overflowY: 'auto' }}>
                            {cPending.map(c => <NotificationElement
                                icon={<Person fontSize="inherit" />}
                                title={c.client.name}
                                pills={[
                                    { label: new Date(c.created).toLocaleDateString('pt-PT', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }) },
                                    (is_admin ? { label: c.agent.name } : null)
                                ]}
                                url={'/clientes/' + c.id}
                            />)}
                        </Grid>
                    }
                </Grid>
            </Grid>

            <Grid container sx={{ backgroundColor: 'white' }} direction={'column'} p={3} xs={12} mt={3}>
                <Typography variant="h6" mb={0}>Estatísticas</Typography>
                <Typography variant="" fontWeight={'bold'} mt={2}>Globais</Typography>
                <Grid container direction={'row'} gap={3}>
                    { stats.properties && <StatsElement number={stats.properties} icon={<Home fontSize="inherit" />} label={'Propriedades'} />}
                    { stats.clients && <StatsElement number={stats.clients} icon={<Person fontSize="inherit" />} label={'Clientes'} />}
                    { stats.forms && <StatsElement number={stats.forms} icon={<Chat fontSize="inherit" />} label={'Formulários de contacto'} />}
                    { stats.visits && <StatsElement number={stats.visits} icon={<Event fontSize="inherit" />} label={'Visitas'} />}
                    { stats.classifications && <StatsElement number={stats.classifications} icon={<AttachFile fontSize="inherit" />} label={'Qualificações'} />}
                </Grid>

                <Typography variant="" fontWeight={'bold'} mt={2}>Sociais</Typography>
                <Grid container direction={'row'} gap={3}>
                    { stats.facebook && <StatsElement number={stats.facebook} icon={<Facebook fontSize="inherit" />} label={'Facebook'} />}
                    { stats.twitter && <StatsElement number={stats.twitter} icon={<Twitter fontSize="inherit" />} label={'X'} />}
                    { stats.linkedin && <StatsElement number={stats.linkedin} icon={<LinkedIn fontSize="inherit" />} label={'LinkedIn'} />}
                    { stats.pinterest && <StatsElement number={stats.pinterest} icon={<Pinterest fontSize="inherit" />} label={'Pinterest'} />}
                    { stats.messenger && <StatsElement number={stats.messenger} icon={<FacebookOutlined fontSize="inherit" />} label={'Messenger'} />}
                    { stats.whatsapp && <StatsElement number={stats.whatsapp} icon={<WhatsApp fontSize="inherit" />} label={'Whatsapp'} />}
                    { stats.telegam && <StatsElement number={stats.telegam} icon={<Telegram fontSize="inherit" />} label={'Telegram'} />}
                    { stats.email && <StatsElement number={stats.email} icon={<Email fontSize="inherit" />} label={'Email'} />}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Homepage;