import React, { useState, useEffect } from "react";

import {
    Grid,
    Typography,
    Button,
    Alert
} from '@mui/material';

import SelectTable from "../../../Components/Chavelusa/SelectTable";

import {
    buildParams,
    requestGET,
    handleRequestError
} from '../../../utils';

import Window from "../../../Components/Chavelusa/Window";

import { useNavigate } from "react-router";

let aborter = null;

/**
 * Optional parameters when page is used as an inner component
 * 
 * @param {Method} (Optional) goBack When defined, a go back button is displayed, which calls this method when activated
 * @param {Object} (Optional) initialParams Initial value for parameters
 * @param {Method} (Optional) onSubmit Method called when user submits rows selection (array with property ids)
 */
const PropertyAdvertsList = ({
    goBack, initialParams, onSubmit, defaultOrderBy
}) => {

    // Loading
    const [loading, setLoading] = useState(true);
    // Content loading and management
    const utils = require('./utils.js');
    const url = process.env.REACT_APP_API_URL + '/externalApps/properties/';
    const [params, setParams] = useState(initialParams ? initialParams : {});
    const [data, setData] = useState(null);

    // When component initialized, load filters
    const [apps, setApps] = useState(undefined);
    const [cols, setCols] = useState(undefined);
    useEffect(() => {
        requestGET(process.env.REACT_APP_API_URL + '/externalApps/apps')
            .then(response => {
                let result = [...utils.cols, ...response.map(app => ({id: 'app:'+app.id, label: app.name}))];
                setCols(result);
                setApps(response);

                setFiltersData(f => { return { ...f, apps: response } });
            }).catch(error =>
                handleRequestError(error, [], "Error getting apps")
            );

        loadFilters();      
    }, []);

    useEffect(() => {
        // Load clients from API
        const timeOutId = setTimeout(() => loadData(), 500);
        return () => clearTimeout(timeOutId);
    }, [params]);

    // Get data from API
    const loadData = () => {
        setLoading(true);

        // cancel pending request if any
        if (aborter) aborter.abort();
        // make our request cancellable
        aborter = new AbortController();
        const signal = aborter.signal;

        requestGET(url + buildParams(params), signal)
            .then(response => {
                aborter = null;
                setData(response);
                setLoading(false);
            }).catch(error =>
                handleRequestError(
                    error,
                    [],
                    "Error getting clients list"
                )
            ).finally(() => {
                aborter = null;
            });
    }

    // Get filters from API
    const [filters, setFilters] = useState([]);
    const [filtersData, setFiltersData] = useState({});
    const loadFilters = () => {
        
    }
    useEffect(() => {
        setFilters(utils.buildFilters(filtersData));
    }, [filtersData]);

    const addParam = (newVals) => {
        setParams({
            ...params,
            ...newVals
        })
    }

    /**
     * A method to update current state 
     * @param {String[]} paramsRemove An array with the ids of the fields to delete
     * @param {String} id The id of the field to update 
     * @param {*} val The value to update the last with     
     */
    const updateParams = (paramsRemove, id = null, val = null) => {
        let copy = null;
        if (id) {
            copy = { ...params, [id]: val }
        } else {
            copy = { ...params }
        }
        paramsRemove.forEach((key) => {
            delete copy[key];
        })
        setParams(copy);
    }

    // Window 
    const [window, setWindow] = useState(undefined);
    const [windowChanged, setWindowChanged] = useState(false);
    useEffect(() => {
        // When window changes, reset changed property
        if (window === null)
            setWindowChanged(false);
    }, [window]);

    // Add entity 
    const navigate = useNavigate();

    return (
        <Grid className="property_list">
            {
                apps && cols && 
                <SelectTable
                    goBack={goBack}
                    entityNameSingular={'anúncio'}
                    data={data}
                    loading={loading}
                    getCols={(lowDensityMode) => cols}
                    getRow={(obj) => utils.getRow(obj, apps)}
                    defaultOrder='desc'
                    defaultOrderBy={defaultOrderBy ? defaultOrderBy : 'created'}
                    filters={filters}
                    params={params}
                    addParam={addParam}
                    updateParams={updateParams}
                    onSubmit={onSubmit}
                    allowSelect={!!utils.enableSelect}
                    allowSearch={!!utils.enableSearch}
                />
            }

            {
                window &&
                <Window close={() => setWindow(null)} hasChanged={windowChanged}>{window}</Window>
            }
        </Grid>
    );
}

export default PropertyAdvertsList;