
import FormGroupLoader from "../../../Chavelusa/FormGroupLoader";

import { buildFilterText, buildFilterSelect } from "../../../Chavelusa/FormGroup/utils";

export const presentationForm = (presentation, updateEntity, onChange) => {
    return (<FormGroupLoader
        title={presentation ? 'Editar apresentação' : 'Criar apresentação'}
        urlPost={'/realstate/presentations/' + (!!presentation ? presentation.id + '/' : '')}
        edit={!!presentation}
        initialVals={{
            ...presentation,
            filter_type: !presentation ? undefined : presentation.filterProperties && presentation.filterProperties.length > 0 ? 'property_ids' : 'county_state'
        }}
        onSubmitSuccess={updateEntity}
        updateParams={onChange}
        fields={[
            buildFilterText('Número de fotos por propriedade', 'maxPhotosPerProperty', true, (val) => val > 0, undefined, 'number'),
            buildFilterText('Número de segundos por foto', 'secondsPerPhoto', true, (val) => val > 0, undefined, 'number'),
            {
                ...buildFilterText('Notas', 'notes'),
                multiline: true,
                lines: 1
            },
            buildFilterSelect('Tipo de filtragem', 'select', 'filter_type', [
                { label: 'Listagem de referências', id: 'property_ids' },
                { label: 'Combinação concelhos e estados', id: 'county_state' },
            ], null, true, null, undefined, false),
            {
                ...buildFilterSelect('Referências', 'multipleselect', 'filterProperties.property.id', [],
                    [{ id: 'filter_type', values: ['property_ids'] }], true, null, undefined, true
                ),
                api: {
                    url: '/realstate/properties/all?archived=false&ordering=id',
                    labelTag: 'id',
                    idTag: 'id',
                    patchArray: 'filterProperties',
                    patchObject: 'property.id'
                }
            },
            {
                ...buildFilterSelect('Concelhos', 'multipleselectchips', 'filterCounties.county.id', [],
                    [{ id: 'filter_type', values: ['county_state'] }], true, null, undefined, true
                ),
                api: {
                    urlBase: process.env.REACT_APP_API_PUBLIC_URL,
                    url: '/realstate/counties',
                    labelTag: 'name',
                    idTag: 'id',
                    patchArray: 'filterCounties',
                    patchObject: 'county.id'
                }
            },
            {
                ...buildFilterSelect('Estados', 'multipleselectchips', 'filterStates.state', [],
                    [{ id: 'filter_type', values: ['county_state'] }], true, null, undefined, true
                ),
                api: {
                    urlBase: process.env.REACT_APP_API_PUBLIC_URL,
                    url: '/realstate/filters',
                    labelTag: 'name',
                    idTag: 'id',
                    bodyAttr: 'states',
                    patchArray: 'filterStates',
                    patchObject: 'state'
                }
            },
        ]}
    />);
}