import * as React from 'react';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@material-ui/utils';

import {
    Box,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Checkbox,
    Tooltip
} from '@material-ui/core';


import {
    CircleOutlined as CircleOutlinedIcon,
    CheckCircle as CheckCircleIcon
} from '@mui/icons-material';

/**
 * Renders table header
 * 
 * @param {Object[]} cols List of objects {id: <String>, label: <String>, sort: <Boolean>}
 * @param {method} onSelectAllClick Method that handles the select all event
 * @param {String} order Sorting order: [asc, desc]
 * @param {String} orderBy Id of the column to oder by
 * @param {method} onRequestSort(orderBy) Changes the orderBy and inverts the order
 * @param {Integer} numSelected Number of rows selected
 * @param {Integer} rowCount Total number of table rows
 */
function SelectTableHeader({ cols, onSelectAllClick, order, orderBy, onRequestSort, numSelected, rowCount, allowSelect, selectedFromPrevPage }) {

    const createSortHandler = (property) => () => {
        onRequestSort(property);
    };

    return (
        <TableHead>
            <TableRow>
                {cols.map((col) => (
                    <TableCell
                        key={col.id}
                        align={'left'}
                        sortDirection={orderBy === col.id ? order : false}
                        sx={{
                            border: 'none',
                            color: 'secondary.main',
                            width: col.width ? col.width : 'auto'
                        }}
                    >
                        {
                            col.sort
                                ?
                                <TableSortLabel
                                    active={orderBy === col.id}
                                    direction={orderBy === col.id ? (order=='asc' ? 'desc' : 'asc')  : 'asc'}
                                    onClick={createSortHandler(col.id)}
                                >
                                    {col.label}
                                    {orderBy === col.id ? (
                                        <Box component="span" sx={visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                                :
                                col.label
                        }
                    </TableCell>
                ))}
                {
                    allowSelect && 
                    <TableCell
                        padding="checkbox"
                        sx={{
                            border: 'none'
                        }}
                    >
                        {
                            rowCount > 1 &&
                            <Tooltip title={(numSelected-selectedFromPrevPage)==rowCount ? "Desselecionar todos" : "Selecionar todos"}>
                                <Checkbox
                                    color="primary"
                                    indeterminate={(numSelected-selectedFromPrevPage) > 0 && (numSelected-selectedFromPrevPage) < rowCount}
                                    checked={rowCount > 0 && (numSelected-selectedFromPrevPage) === rowCount}
                                    onChange={onSelectAllClick}
                                    inputProps={{
                                        'aria-label': 'select all desserts',
                                    }}
                                    icon={<CircleOutlinedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                    sx={{
                                        right: '-50%'
                                    }}
                                />
                            </Tooltip>
                        }
                    </TableCell>
                }
            </TableRow>
        </TableHead>
    );
}

SelectTableHeader.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default SelectTableHeader;