import React, {useState, useEffect} from "react";

import FormGroup from '../../../../Chavelusa/FormGroup';

import {
    requestWithBody,
    requestGET,
    handleRequestError
} from "../../../../../utils";

import {
    buildFilterText,
    buildFilterRadio,
    buildFilterCheckboxOnlyOne,
    buildFilterSelect,
    validatorIsInteger
} from '../../../../Chavelusa/FormGroup/utils';

import { 
    Grid,
    Typography,
    CircularProgress
} from '@mui/material';

const fields_initial = [
    buildFilterRadio("Tipo de cliente", "clientType", [
        {label: 'Comprador', id:'C'},
        {label: 'Arrendatário', id:'A'},
    ], true),
    buildFilterCheckboxOnlyOne("O cliente tem financiamento", "financing", false),
    buildFilterCheckboxOnlyOne("Aceita trespasse", "transfer", false),
    buildFilterSelect("O cliente está interessado em...", "multipleselectchips", "propertyTypes", [], null, true),
    buildFilterText("Número de quartos procurado", "bedrooms", true, validatorIsInteger, "Por favor insira um número válido", 'number'),
    buildFilterText("Investimento disponível", "investment", true, validatorIsInteger, "Por favor insira um número válido", 'number'),
]

const ClientClassificationFormInitial = ({clientAgentId, classification, success, formChanged}) => {

    
    const edit = !!classification;
    const url = 
        edit 
        ? process.env.REACT_APP_API_URL + '/crm/classifications/' + classification.id + '/?clientAgent=' + clientAgentId
        : process.env.REACT_APP_API_URL + '/crm/classifications/';
    
    console.log("ClientClassificationFormInitial edit?", edit);

    // If edit, build initial_values for form 
    var initial_vals = {};
    if (edit) {
        initial_vals = {
            clientType: fields_initial[0].values.filter(f => f.label===classification['clientType'])[0].id,
            financing: classification['financing'],
            transfer: classification['transfer'],
            bedrooms: classification['bedrooms'].toString(),
            investment: classification['investment']
        };
        initial_vals['propertyTypes'] = classification['propertyTypes'].map(pt => pt['propertyType'].id);
    }
    console.log("initial_vals", initial_vals);

    const [fields, setFields] = useState(fields_initial);

    // Load options from API
    const loadingMonitor = [true];
    const [loading, setLoading] = useState(loadingMonitor.some(l => l));
    useEffect(() => {
        requestGET(process.env.REACT_APP_API_URL + '/realstate/property_types/').then(
            response => {
                console.log("Got /realstate/property_types/", response);
                setFields(f => {
                    f[3]['values'] = response.map(r => {return {label: r.name, id: r.id};});
                    return f;
                });
                loadingMonitor[0] = false;
                setLoading(loadingMonitor.some(l => l));
            }
        );
    }, []);

    // Submit qualification
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(undefined);
    const onSubmit = (vals) => {
        setSubmitting(true);

        // Update vals to POST expected structure
        let vals_local = {...vals};
        vals_local['propertyTypes_ids'] = vals['propertyTypes'].map(id => {return {id: id}});
        delete vals_local['propertyTypes'];
        vals_local['financing'] = !!vals['financing']; // Double negation to convert null to false
        vals_local['transfer'] = !!vals['transfer'];
        vals_local['clientAgent'] = clientAgentId;

        console.log("Submit qualification...", vals_local);

        // POST/PATCH to API
        requestWithBody(
            edit ? "PATCH" : "POST",
            url,
            vals_local
        ).then(response => {
            console.log(
                edit ? "Qualification updated with success!" : "Qualification created with success!", 
                response
            );
            success(response);
        }).catch(error => 
            handleRequestError(
                error, 
                fields.map(f => f.id), 
                edit ? "Error updating qualification!" : "Error creating qualification!"
            )
            .then(e => setError(e))
        ).finally(() => {
            setSubmitting(false);
        });
    }

    return (
        <Grid container direction="column">
            <Typography variant="h6" mb={0}>
                Dados gerais
            </Typography>
            {
                !loading && 
                <FormGroup 
                    fields={fields}
                    onSubmit={onSubmit}
                    disabled={submitting}
                    errors={error}
                    setErrors={setError}
                    initialVals={initial_vals}
                    sx={{
                        minWidth: '100%',
                        maxWidth: '100%',
                        mr: 0,
                        my: 1
                    }}
                    updateParams={() => formChanged()}
                />
            }
            {
                loading && <CircularProgress sx={{ mt: 'auto', mx: 'auto' }} />
            }
        </Grid>
    );
}

export default ClientClassificationFormInitial;