import React, { useState, useEffect } from "react";

import {
    Grid,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    FormLabel,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    Slider,
    Switch,
    TextField,
    Button,
    Alert,
    FormHelperText,
    Box,
    Chip,
    ListSubheader,
    Autocomplete,
    Tooltip,
    Typography
} from '@material-ui/core';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Save as SaveIcon, Info as InfoIcon, Lock as LockIcon } from '@mui/icons-material';

import LoadingButton from '@mui/lab/LoadingButton';

// Date picker
import DateTimePicker from 'react-datetime-picker';

import { default as ChaveLusaAlert } from "../Alert";
import {
    resetFieldValue
} from './utils';
import {
    getCookie
} from '../../../utils';
import { ROLE, translateRole } from "../../../../Services/permissions";

/**
 * This component renders a fom group, with several fields supported.
 * 
 * It handles all the rendering and state change logic communicating the changes to the upper element.
 * It also supports dependencies (only showing one field if other has certain value).
 * If a dependency stops behing met, the value of the dependent is reset.
 * 
 * @param {Array} fields A list of objects. See ./utils#buildFilter<> methods to find the required structure.
 * @param (Optional) {Object} sx Style properties for FormControl element
 * @param (Optional) {method} updateParams A method that accepts (to_clean, id, val) as arguments:
 *          - to_clean An array with the ids of the fields to delete
 *          - id The id of the field to update
 *          - val The value to update the last with      
 * @param (Optional) {method} onSubmit 
 *          Alternatively to update the state of the upper component everytime the form state changes (for this use updateParams),
 *          with onSubmit, the upper component is only notified of the updates when the user clicks on the submit button.
 *          The method is only triggered if the form fields comply with the validations def
 * @param (Optional) {boolean} disabled Variable that disabled submit button, fields and hides fields errors
 * @param (Optinal) {dictionary} errors See API.md#Errors to see supported structures
 * @param (Optional) initialVals Values to initialize the form (for editting) { <id>: <val> }
 * 
 * Error handling
 * @param (Optional) {Object} errors JSON object width the following structure:
 *  { <name of attr with error:String>: [ <error1:String>, <error2:String>, ... ], ... } 
 *  { "error": <custom error type>, <aditional fields for custom type error>...  }
 * @param (Optional) {Method} setErrors Change value of errors, when value of field with server error changes they are cleaned
 *          up because it is assumed that because they have changed they are now potencially correct
 * @param (Optional) {Method} setInvalid Method called to propagate to upper components name of invalid fields
 * @param (Optional) {Method} onDelete If defined, a DELETE button is showed below SUBMIT, which triggers this method (without parameters)
 * @param (Optional) {Method} onSearch If defined, when user writes on autoComplete TextInput, this method is triggerred with (id, searchTerm)
 */


const FormGroup = ({
    fields, sx = {}, updateParams, initialVals = {}, onSubmit, disabled, errors, setErrors, setInvalid, onDelete, onSearch,
    submitText
}) => {

    console.log("FormGroup initial values", initialVals);

    const [vals, setVals] = useState(initialVals);

    useEffect(() => {
        console.log("State update initialVals", initialVals, { ...vals, ...initialVals });
        if (!initialVals || !Object.keys(initialVals).length) return;
        setVals(oldV => { return { ...oldV, ...initialVals } });
        // setErrors && setErrors(undefined);
    }, [initialVals]);

    const updateField = (id, val) => {
        console.log("FORM updateField", id, val);

        // Check dependencies to validate if there is a need to reset fields
        let to_clean = [];
        // .. and prepare self state update
        let copy = null;
        if (id) {
            copy = { ...vals, [id]: val }
        } else {
            copy = { ...vals }
        }
        // Find fields that depend on the one changed
        fields.forEach((field) => {
            if ('dependency' in field && field['dependency'] && field['dependency'].length) {
                field['dependency'].forEach(dependency => {
                    if (dependency.id === id) {
                        // If [id] new value does not match expected
                        if (dependency.values.indexOf(val) < 0) {
                            // Add to reset list
                            to_clean.push(field.id);
                            copy[field.id] = resetFieldValue(field);
                        }
                    }
                })
            }
        });
        // If field on errors, remove it because it has changed and now is possibly correct (to unlock form submit)
        if (errors && setErrors) {
            console.log("updating errors...", errors);
            if (id in errors) {
                console.log("Removing field as key")
                setErrors(oldErrors => {
                    delete oldErrors[id];
                    return oldErrors;
                });
            }
            if ('fields' in errors && errors['fields'].indexOf(id) >= 0) {
                console.log("Removing field from errors#fields");
                setErrors(oldErrors => {
                    oldErrors['fields'].splice(errors['fields'].indexOf(id), 1);
                    return oldErrors;
                });
            }
        }
        // Update self state
        setVals(copy);
        // Update upper component state (reseting dependencies)
        updateParams && updateParams(to_clean, id, val);
    }

    // Everytime the form is updated validate fields
    // Also, when errors are updated, update invalid fields
    const [invalidFields, setInvalidFields] = useState({});
    useEffect(() => {
        console.log("State update", vals, errors);
        let invalidRefresh = {}
        if (errors)
            invalidRefresh = analyseErrors(errors);
        console.log("invalidRefresh", invalidRefresh);

        // A field is invalid if...
        fields.forEach(field => {
            const field_has_value = field.id in vals && vals[field.id] !== undefined && vals[field.id] !== null && (
                Array.isArray(vals[field.id]) && vals[field.id].length
                || typeof vals[field.id] === 'string' && vals[field.id].trim()
                || !isNaN(vals[field.id]) && (parseInt(vals[field.id]) > 0 || field.acceptsZero && parseInt(vals[field.id]) >= 0)
                || field.type === 'datetime' && vals[field.id] instanceof Date
            );
            // (Ignore if field depends on and other has not required value)
            if (!!field.dependency && !field.dependency.some(d => !!vals[d.id] && d.values.some(v => v === vals[d.id]))) {
                return;
            }
            // ... is mandatory and has no value
            if (field.mandatory && !field_has_value) {
                invalidRefresh[field.id] = "Este campo é de preenchimento obrigatório";
            }
            // ... or has value and a validator and the first is not valid according to the second
            if (field_has_value && field.validator && !field.validator(vals[field.id])) {
                invalidRefresh[field.id] = field.errorMessage ? field.errorMessage : "O valor introduzido é inválido";
            }
        });

        setInvalidFields(invalidRefresh);
        setInvalid && setInvalid(invalidRefresh);
    }, [vals, errors]);

    useEffect(() => {
        console.log("State update vals only", vals);
    }, [vals]);

    // option.errors has the error returned by the server 
    const [errorMessage, setErrorMessage] = useState(undefined);
    // Returns fields that are invalid based on error (see structures suppoted at API.md#Errors) 
    const analyseErrors = () => {
        console.log("Analysing fom errors", errors);
        let fields_error = {};

        // Custom errors
        if (errors && 'error' in errors) {
            if (errors['error'] === 'DUPLICATE') {
                setErrorMessage('Os campos assinalados colidem com os seguintes clientes: ' + errors['duplicates'].join(', '));
                errors['fields'].forEach(f => { fields_error[f] = 'Campo duplicado' });
            } else if (errors['error'] === 'UNKNOWN') {
                // Django REST default errors
                if ('detail' in errors) {
                    setErrorMessage(errors['detail']);
                } else {
                    setErrorMessage('Ocorreu um erro ao realizar a operação, por favor tente novamente.');
                }
            }
            // Default field valued
        } else if (errors) {
            fields.forEach(f => {
                if (f.id in errors && errors[f.id].length) {
                    fields_error[f.id] = errors[f.id].join(',');
                }
            })
        }
        return fields_error;
    }

    // Alert before closing when changed
    const [alert, setAlert] = useState(undefined);
    const ALERT_CONFIRM_DELETE = {
        title: 'Esta operação é irreversível!',
        text: 'Tem a certeza que pretende eliminar esta entidade?',
        action: onDelete
    };

    return (
        <Grid sx={{ flexGrow: 1 }}>
            {
                (errors || errorMessage) &&
                <Alert sx={{ my: 3 }} severity="error">
                    {
                        errorMessage
                            ? errorMessage
                            : "Ocorreu um erro na submissão do formulário. Por favor reveja os campos e tente novamente."
                    }
                </Alert>
            }
            {
                // For each field
                fields != undefined && fields.map((field) => {
                    // Add it to self state if not already
                    if (!(field.id in vals)) {
                        setVals({
                            ...vals,
                            [field.id]: ['multipleselectchips', 'multipleselect', 'checkboxmultiple'].indexOf(field.type) >= 0 ? [] : null
                        })
                    }

                    // Validate dependencies
                    if ('dependency' in field && field['dependency'] && field['dependency'].length) {
                        const dependencyValid = field['dependency'].map(dependency => {
                            // dependency id must exist in self state (or undefined is a valid value for it)
                            if (!Object.keys(vals).includes(dependency['id'])) {
                                if (dependency['values'].includes(undefined))
                                    return true;
                                return false;
                            }
                            // dependency value must match one of the expected, or have one value if '*' in values
                            if (
                                !dependency['values'].includes(vals[dependency['id']]) &&
                                !dependency['values'].includes('*')
                            ) {
                                return false;
                            }
                            return true;
                        })
                        if (dependencyValid.length && !dependencyValid.every(v => v)) {
                            return null;
                        };
                    }

                    // If field does not have unmet dependency, render
                    return (
                        <FormControl
                            variant="standard"
                            sx={sx}
                            disabled={disabled}
                        >
                            {
                                ['multipleselect', 'multipleselectchips', 'select', 'slider', 'richtext'].indexOf(field.type) >= 0 && !field.autoComplete &&
                                <InputLabel
                                    id={'form-' + field.id + '-label'}
                                    required={field.mandatory}
                                    error={!disabled && Object.keys(invalidFields).indexOf(field.id) >= 0}
                                    sx={{
                                        position: field.type === 'richtext' ? 'relative' : 'absolute',
                                        mb: field.type === 'richtext' ? 3 : 0
                                    }}
                                >
                                    {field.label}
                                </InputLabel>
                            }
                            {
                                field.type.includes('select') &&
                                <>
                                    {
                                        field.autoComplete
                                            ? <Autocomplete
                                                sx={{mt: 1}}
                                                multiple={field.type.includes("multiple")}
                                                id={'form-' + field.id}
                                                options={
                                                    field.type.includes("multiple") && field.mandatory
                                                    ? [{id: '*', label: 'Todos'}, ...field.values]
                                                    : field.values
                                                }
                                                renderInput={(params) => <TextField {...params} label={field.label} />}
                                                onChange={(_, val) => {
                                                    console.log("option change", val);
                                                    updateField(
                                                        field.id,
                                                        val ? (
                                                            field.type.includes("multiple") 
                                                                ? (
                                                                    val.some(v => v.id==='*')
                                                                    ?
                                                                    field.values.map(v => v.id)
                                                                    : val.map(v => {
                                                                        // Only new option is object, others are already v.id
                                                                        if (typeof(v) === 'object') return v.id; 
                                                                        return v;
                                                                    }) 
                                                                )
                                                                : val.id
                                                        ) : null
                                                    )
                                                }}
                                                // When API, disable Autocomplete text filter, as filtering is carried through API call
                                                filterOptions={field.api && field.api.url ? (options, state) => options : undefined}
                                                value={(field.id in vals && !!vals[field.id]) ? vals[field.id] : resetFieldValue(field)}
                                                disabled={field.disabled}
                                                getOptionLabel={(option) => {
                                                    // With initial value, option will be object
                                                    if (typeof (option) === 'object') return option.label ? option.label.toString() : "?";
                                                    console.log("option", option)
                                                    // ... but when value changes, it will be the value of the option selected
                                                    let candidate = field.values.find(val => val.id === option);
                                                    return candidate ? candidate.label : "";
                                                }}
                                                isOptionEqualToValue={(op, val) => {
                                                    return op.id===val || typeof(val) === 'object' && op.id===val.id;
                                                }}
                                                onInputChange={(_, val) => onSearch && onSearch(field.id, val)}
                                                limitTags={field.type === 'multipleselect' ? 0 : -1}
                                                getLimitTagsText={(n) => `${n} ${field.label}`}

                                            />
                                            : <Select
                                                multiple={field.type.includes("multiple")}
                                                labelId={'form-' + field.id + '-label'}
                                                id={'form-' + field.id}
                                                label={field.label}
                                                value={(field.id in vals && !!vals[field.id]) ? vals[field.id] : resetFieldValue(field)}
                                                onChange={(event) => {
                                                    if (field.type.includes("multiple") && event.target.value && event.target.value.includes("")) {
                                                        updateField(field.id, resetFieldValue(field.type));
                                                    } else {
                                                        updateField(field.id, event.target.value);
                                                    }
                                                }}
                                                disabled={field.disabled}
                                                renderValue={(value) => {
                                                    if (field.type === 'multipleselect')
                                                        return `${value.length} ${field.label}`;
                                                    else if (field.type === 'multipleselectchips')
                                                        return (
                                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                {
                                                                    field.values.filter(v => value.indexOf(v.id) >= 0).map(v => <Chip key={v.id} label={v.label} />)
                                                                }
                                                            </Box>
                                                        )

                                                    // Otherwise, one option
                                                    let fQuery = field.values.find(f => f.id === value);
                                                    if (fQuery) return fQuery.label;
                                                    return value;
                                                }}
                                            >
                                                {
                                                    <MenuItem value="">
                                                        {
                                                            !field.mandatory
                                                                ? <em>Todos</em>
                                                                : <em>Nenhum</em>
                                                        }
                                                    </MenuItem>
                                                }
                                                {
                                                    field.values.map((val) =>
                                                        val.group
                                                            ?
                                                            <ListSubheader>{val.label}</ListSubheader>
                                                            :
                                                            <MenuItem value={val.id} key={val.label}>
                                                                {
                                                                    field.type.includes("multiple") && vals[field.id] != undefined &&
                                                                    <Checkbox checked={vals[field.id].indexOf(val.id) > -1} />
                                                                }
                                                                {val.label}
                                                            </MenuItem>
                                                    )
                                                }
                                            </Select>
                                    }
                                    {
                                        !disabled && Object.keys(invalidFields).indexOf(field.id) >= 0 &&
                                        <FormHelperText error={true}>{invalidFields[field.id]}</FormHelperText>
                                    }
                                </>
                            }
                            {
                                field.type === 'slider' &&
                                <Slider
                                    labelId={'form-' + field.id + '-label'}
                                    defaultValue={field.min}
                                    min={field.min}
                                    max={field.max}
                                    onChangeCommitted={(event, val) => updateField(field.id, val)}
                                    getAriaValueText={() => vals[field.id]}
                                    step={1}
                                    marks={[
                                        { value: field.min, label: field.min },
                                        { value: field.max, label: field.max },
                                    ]}
                                    valueLabelDisplay="auto"
                                    sx={{
                                        mt: 7,
                                    }}
                                />
                            }
                            {
                                field.type === 'switch' &&
                                <FormControlLabel
                                    control={<Switch defaultChecked />}
                                    label={field.disabled && field.info ? <Tooltip title={field.info}><Typography><LockIcon fontSize="inherit" sx={{ mr: 1 }} />{field.label}</Typography></Tooltip> : field.label}
                                    defaultChecked={field.default}
                                    checked={vals[field.id] ? vals[field.id] : false}
                                    onChange={(event) => updateField(field.id, event.target.checked)}
                                    disabled={!!field.disabled}
                                />
                            }
                            {
                                field.type === 'text' &&
                                <TextField
                                    id={field.id}
                                    label={field.disabled && field.info ? <Tooltip title={field.info}><Typography><LockIcon fontSize="inherit" sx={{ mr: 1 }} />{field.label}</Typography></Tooltip> : field.label}
                                    variant="standard"
                                    value={vals[field.id]}
                                    required={field.mandatory}
                                    onChange={(event) => updateField(field.id, event.target.value)}
                                    error={!disabled && Object.keys(invalidFields).indexOf(field.id) >= 0}
                                    helperText={
                                        Object.keys(invalidFields).indexOf(field.id) >= 0
                                            ? invalidFields[field.id]
                                            : field.info && !field.disabled
                                                ? field.info
                                                : undefined
                                    }
                                    type={field.inputType}
                                    multiline={field.multiline}
                                    minRows={field.multiline && field.lines}
                                    inputProps={field.maxLength ? { maxLength: field.maxLength } : {}}
                                    disabled={field.disabled}
                                />
                            }
                            {
                                field.type === 'radio' &&
                                <>
                                    <FormLabel
                                        component="legend"
                                        required={field.mandatory}
                                        error={!disabled && Object.keys(invalidFields).indexOf(field.id) >= 0}
                                    >{field.label}</FormLabel>
                                    <RadioGroup
                                        aria-label={field.label}
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                        onChange={(event) => updateField(field.id, event.target.value)}
                                        value={vals[field.id]}
                                        row={!!field.row}
                                    >
                                        {
                                            field.values.map(v =>
                                                <FormControlLabel value={v.id} control={<Radio disabled={field.disabled} />} label={v.label} />
                                            )
                                        }
                                    </RadioGroup>
                                    {
                                        !disabled && Object.keys(invalidFields).indexOf(field.id) >= 0 &&
                                        <FormHelperText error={true}>{invalidFields[field.id]}</FormHelperText>
                                    }
                                </>
                            }
                            {
                                field.type === 'checkboxonlyone' &&
                                <>
                                    <FormControlLabel
                                        label={field.label}
                                        required={field.mandatory}
                                        control={<Checkbox />}
                                        error={!disabled && Object.keys(invalidFields).indexOf(field.id) >= 0}
                                        onChange={(event) => updateField(field.id, event.target.checked)}
                                        checked={!!vals[field.id]}
                                    />
                                    {
                                        !disabled && Object.keys(invalidFields).indexOf(field.id) >= 0 &&
                                        <FormHelperText error={true}>{invalidFields[field.id]}</FormHelperText>
                                    }
                                </>
                            }
                            {
                                field.type === 'checkboxmultiple' &&
                                <>
                                    <FormLabel
                                        component="legend"
                                        sx={{ width: '100%' }}
                                        error={!disabled && Object.keys(invalidFields).indexOf(field.id) >= 0}
                                        required={field.mandatory}
                                    >
                                        {field.label}
                                    </FormLabel>
                                    {
                                        field.values.map(val =>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                    />
                                                }
                                                label={val.label}
                                                sx={{ width: field.valsWidth, mr: 0 }}
                                                error={!disabled && Object.keys(invalidFields).indexOf(field.id) >= 0}
                                                onChange={(event) => {
                                                    let old_val = vals[field.id];
                                                    if (event.target.checked && old_val.indexOf(val.id) < 0)
                                                        old_val.push(val.id);
                                                    else if (!event.target.checked && old_val.indexOf(val.id) >= 0)
                                                        old_val.splice(old_val.indexOf(val.id), 1);
                                                    updateField(field.id, old_val);
                                                }}
                                                checked={field.id in vals && vals[field.id].indexOf(val.id) >= 0}
                                            />
                                        )
                                    }
                                </>
                            }
                            {
                                field.type === 'datetime' &&
                                <>
                                    {
                                        field.label &&
                                        <FormLabel
                                            component="legend"
                                            required={field.mandatory}
                                            error={!disabled && Object.keys(invalidFields).indexOf(field.id) >= 0}
                                        >{field.label}</FormLabel>
                                    }
                                    <DateTimePicker
                                        value={vals[field.id] ? new Date(vals[field.id]) : undefined}
                                        onChange={(newValue) => {
                                            if (newValue)
                                                updateField(field.id, newValue.toISOString());
                                            else
                                                updateField(field.id, newValue.toISOString());
                                        }}
                                        disableClock={field.date}
                                        disableCalendar={!field.date}
                                        format={field.date && field.time ? 'dd/MM/yyyy HH:mm' : field.date ? 'dd/MM/yyyy' : 'HH:mm'}
                                        locale={'pt'}
                                        disabled={!!field.disabled}
                                        minDate={field.minDate ? field.minDate : undefined}
                                        maxDate={field.maxDate ? field.maxDate : undefined}
                                        clearIcon={null}
                                    />
                                </>
                            }
                            {
                                /* https://github.com/zenoamaro/react-quill */
                                field.type === "richtext" &&
                                <ReactQuill
                                    value={field.id in vals ? vals[field.id] : ''}
                                    onChange={(val) => updateField(field.id, val)}
                                    modules={{
                                        toolbar: [
                                            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                            ['blockquote'],

                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                                            [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent

                                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                                            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                            [{ 'align': [] }],

                                            ['clean']                                         // remove formatting button
                                        ]
                                    }}
                                />
                            }
                            {
                                !!field.permissionDenied &&
                                <Typography variant="caption" color={'secondary'}>Não tem permissões para editar este campo</Typography>
                            }
                        </FormControl>
                    )
                })
            }
            {
                onSubmit &&
                (
                    !disabled
                        ?
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{ width: '100%', mt: 3 }}
                            disabled={Object.keys(invalidFields).length}
                            onClick={() => onSubmit(vals)}
                        >
                            {submitText ? submitText : 'Submeter'}
                        </Button>
                        :
                        <LoadingButton
                            loading
                            color="primary"
                            variant="contained"
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            sx={{ width: '100%', mt: 3 }}
                        >
                            Submeter
                        </LoadingButton>
                )
            }
            {
                onDelete &&
                <Button
                    color="primary"
                    variant="outlined"
                    sx={{ width: '100%', mt: 3 }}
                    disabled={disabled}
                    onClick={() => setAlert(ALERT_CONFIRM_DELETE)}
                >
                    Eliminar
                </Button>
            }

            { /* Operations confirmation box */}
            {
                alert &&
                <ChaveLusaAlert
                    title={alert.title}
                    text={alert.text}
                    action={alert.action}
                    close={() => setAlert(undefined)}
                />
            }
        </Grid>
    );

}

export default FormGroup;